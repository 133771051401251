// USA
export const locale = {
  lang: 'en',
  ar: 'العربيه',
  en: 'English',
  currentTheme: 'ltr',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },

    breadcrumbs: {
      customerManagement: "Customer Management",
      addCustomerManagement: "Add New Customer",

      home: 'Home',
      admin: 'Admin',
      agent: 'Agent',
      contact: 'Contact us',
      privacy: 'Privacy Policy',
      question: 'Asked Questions',
      projects: "Project Management",
      usermanagement: 'User Management',
      employeemanagement: 'Employee Management',
      branchmanagement: 'Branch Management',
      editprofile: 'Edit Profile',
      contractmanagement: 'Contract Management',
      viewcontract: 'View Contract',
      viewuser: 'View User',
      addcase: 'Add Case',
      editcase: 'Edit Case',
      viewcase: 'View Case',
      logcase: 'Case Log ',
      addcontract: 'Add Contract',
      casemanagement: 'Case Management',
      addclient: 'Add Client',
      editclient: 'Edit Client',
      clientmanagement: 'Client Management',
      workdutymanagement: 'Task Management',
      addworkduty: 'Add Task',
      editworkduty: 'Edit Task',
      sittingmanagement: 'Court Sessions Management',
      addsitting: 'Add Court Sessions',
      editsitting: 'Edit Court Sessions',
      viewSittingHistory: 'Court Sessions History',
      viewSitting: 'View Court Sessions',
      viewworkduty: 'View Task',
      viewclient: 'View Client',
      contract: 'Contract Management',
      assignedTeam: 'Assigned Team',
      notification: 'Notification',
      addbranch: 'Add Branch',
      editbranch: 'Edit Branch',
      viewbranch: 'View Branch',
      addemployee: 'Add Employee',
      editemployee: 'Edit Employee',
      viewemployee: 'View Employee',
      attornymanagement: 'Power of Attorney Management',
      editattorny: 'Edit Power of Attorney',
      addattorny: 'Add Power of Attorney',
      viewattorny: 'View Power of Attorney',
      payment: 'Payments',
      report: 'report',
      upgradePackage: 'Upgrade Packages',
      invoiceDetails: 'Invoice Details',
      addPackage: 'Add Package',
      editPackage: 'Edit Package',
      packagesmanagement: 'Packages Management',
      Confirm: 'Confirm',
      services: "Services",
      about: 'About QYD',
      filled: "Filled Forms",
      addProject: "Add Project",
      financialDepartment: 'Financial Department',
      accountingSummary: "Accounting Summary",
      ClientManagment: 'Client Managment',
      EmployeeManagment: 'Employee Managment',
      ContractManagment: 'Contract Managment',
      AccountingManagement: 'Accounting Management',

    },
    menu: {
      setting: 'Setting',
      playerManagement: 'Users Management',
      editProfile: 'Edit Profile',
      package: 'Packages Management',
      message: 'Messages and Complaints Management',
      statistics: 'Statistics',
      notifications: 'Notifications',
      department: 'Departments',
      employeeManagement: 'Employee Management',
      home: 'Home',
      about: 'About Qyd',
      packageM: 'Packages',
      contact: 'Contact us',
      dashboard: 'Dashboard',
      login: 'Log in',
      attornymanagement: 'Power of Attorney Management',
      payment: 'Payments',
      filled: 'Filled Forms',
      upgradePackage: 'Upgrade Packages',
      services: 'Services',
      soon: 'This version is provided for testing purposes only and is not currently intended for sale',
    },
    LogIn: {
      SignOut: 'Sign Out',
      qydSuperAdmin: 'Admin',
      infoTitle: 'VAT Registration Information in data form',
      CodeYouEnteredIncorrect: 'The code you entered is incorrect',
      CodeResendSuccess: 'Code Resend Success',
      qMessage: 'Are you registered for VAT?',
      qydAgent: 'Agent',
      yes: 'Yes',
      no: 'No',
      qydEmployee: 'Employee',
      MessageValidation:
        'You must complete your data in order to use the platform.',
      clickIn: 'Click Here',
      attention: 'attention',
    },
    users: {
      QYDAgent: 'Agent',
      QYDSuperAdmin: 'Admin',
      admin: 'admin',
      QYDEmployee: 'Employee',
      QYDManager: 'Branch Manager',
    },
    loginPage: {
      logIn: 'Login',
      logInEmail: 'Email Address',
      thisFieldIsRequired: 'This Field is Required',
      thisFieldIsShouldHaveSpecialCharacter: 'this Field Is Should HaveS pecial Character',
      Password: 'Password',
      rememberme: 'Remember Me',
      CAPTCHA_TITLE: 'Visual Code',
      enterEmail: 'Enter Your Email',
      enterPassword: 'Enter Password',
      ForgotPassword: 'Forget Password?',
      createAccount: 'Create Account',
      noAccount: 'Not SignIn?',
      hasAccount: 'Has Account?',
      minLength: 'The number cannot be less than 1',
      back: 'Back',
    },
    userManagement: {
      playerManagement: 'Users Management',
      userList: 'Users List',
      search: 'Search by name or email',
      searchByName: ' search By Name',
      completed: 'Completed',
      inCompleted: 'Incompleted ',
      active: 'active',
      inActive: 'Inactive ',
      All: 'All',
      management: 'User',
      user: 'Management',
      emailsend: 'Send Email Activation',
      allCustomers: ' Users Management',
      activeMembers: 'Active Members',
      playerName: 'User Name ',
      playerStatus: 'player Status ',
      mobileNumber: 'Mobile Number',
      Email: 'Email',
      country: 'Country',
      Confirmed: 'Confirmed',
      NotConfirmed: 'Not Confirmed',
      accountStatus: ' Account Status',
      emailStatus: ' Email Status',
      Block: 'Block',
      blockStatus: 'Block status',
      Blocked: 'Blocked',
      notBlocked: 'Notblocked',
      Details: 'Details',
      NotFound: 'No data available',
      total: 'total',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change account status?',
      changeAccountStatus: 'Account Status changed successfully',
      ok: 'OK',
      cancel: 'Cancel',
      sendEmail: 'Email resended successfully',
    },
    filledForms: {
      formFilled: "Form Filled",
      requestStatus: "Request Status",
      formsList: "Form List",
      clientName: "Client Name",
      details: "Details"
    },
    register: {
      completeInfo: "Complete Info",
      createAccount: 'Create a New Account',
      back: 'Back',
      reasonType: 'reason Type',
      LegalDepartment: "Legal Department",
      institutionName: 'Organization Name',
      licenseNumber: 'Commercial Registration Number',
      agree: 'I agree to ',
      terms: 'Service Level Agreement',
      signUp: 'signUp',
      hasAccount: 'Already Have an Account?',
      signIn: 'SignIn',
      entername: 'Enter Organization Name',
      enternumber: 'Enter Commercial Registration Number',
      userName: 'Username',
      email: 'Email',
      enterUserName: 'Enter UserName',
      enterEmail: 'Enter Email',
      phoneNumber: 'phoneNumber',
      enterphoneNumber: 'Enter phoneNumber',
      accountType: 'Account Types',
      SelectOption: 'Select City',
      next: 'Next',
      accountTypeRequired: 'Select Account Type',
      LawyerOffice: 'Lawyer Office',
      RegularRepresentative: 'Regular Representative',
      lawyer: ' lawyer',
      Traininglawyer: 'Traininglawyer',
      Licensedlawyer: 'Licensedlawyer',
      LegalAdministration: 'Legal Administration ',
      Advisor: 'Advisor',
      register: 'Sign Up',
      addSuccess: 'Account Added Successfully',
      accountInfo: 'Account Info',
      logo: 'Upload Organization Logo',
      sector: 'Organization Sector',
      organizationSectorsRequired: 'Enter Organization Sectors',
      employeeNo: 'Number of Employees',
      employeeNoRequired: 'Enter Employee Number',
      organizationEmail: 'Organization Email',
      country: 'Country',
      saudi: 'Saudi Arabia',
      countryIdRequired: 'country Is Required',
      region: 'Region',
      regionIdRequired: 'Region Is Required',
      city: 'City',
      cityIdRequired: 'City Is Required',
      currency: 'Currency',
      currencyIdRequired: ' Currency Is Required',
      agentAddress: 'Organization Address',
      agentAddressIsRequired: 'Organization Address Is Required',
      NotificationLanguage: 'Notification Language',
      NotificationLanguageIsRequired: 'Notification Language Is Required',
      arabic: 'Arabic',
      english: 'English',
      save: 'Save',
      addEmailSuccess: 'adding Email Success',
      logoRequired: 'Please, Upload Photo',
      VATRegistrationNumber: 'VAT Registration Number',
      CommercialRegistrationCertificate: 'Commercial Registration Certificate',
      VATCertificate: 'VAT Certificate',
    },
    AgentRegisteration: {
      YourAccountRegisteredSuccessfully: 'Register Successfully',
      ActivateEmailMessage: 'To activate account ,please check your email',
      ActivateEmailMessage2: 'and click on activation link',
      back: 'Back',
    },
    Shared: {
      TheMaximumFileAllowed: 'Logo Size 2MB  maximum',
      CheckUploadedFileFormat: 'Check the uploaded file format',
    },
    forgetPassword: {
      back: 'Back to Home',
      password: 'Password',
      ForgetYourPassword: 'Reset Your Password',
      EnterYourEmail: 'Enter Your Registered Email ',
      APasswordResetLinkWillBeSent: 'to send link of reset password',
      email: 'Email',
      SendLink: 'Send Link',
      resetSuccess: 'Password Reset Successfully',
      PasswordResetLinkWillBeSent: 'Password Reset Link Is Sent to',
      MailTo: 'Mail To',
      DoNotReceiveEmail: 'Do Not Receive Email',
      ResendEmail: 'Resend Email',
      ResetYourPassword: 'Reset Your Password',
      EnterPassword: 'Enter Your Password',
      NewPassword: 'New Password',
      EmailSentSuccessfully: 'Reset Password Link Sent Successfully',
      ConfirmPassword: 'Confirm Password',
      oldPassword: 'Old Password',
      passwordRole:
        'Password must include at least one uppercase letter, one lowercase letter, one special character, and one number',
    },
    confirmEmail: {
      accoutActivate: 'Email Confirmed ',
      accoutActivate2: 'Please Sign in To Complete Your Profile.',
      mainPage: 'Main Page',
      error: 'Error',
      accoutActivateEmployee: 'Please Reset Your Password To Enter Your Board',
      resetPassword: 'Reset Password',
    },
    Validation: {
      EmailIsInValid: 'Email is InValid',
      emailRequired: 'Please Enter Your Email',
      organizationEmailRequired: 'Enter Organization Email',
      passwordRequired: 'Please Enter Your Password',
      PasswordIsRequired: 'Password Is Required',
      invalidPasswordPattern: "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.",
      PasswordPattern: 'Password Pattern',
      PasswordMatch: 'Password Not Matched',
      EmailMatch: 'Email Not Matched',
      UsernameIsInValid: 'Username is Required',
      thisFieldIsRequired: 'This Field is Required',
      englishOnly: 'this field write in english only',
      arabicOnly: 'this field write in arabic only',
      numberonly: 'This Field Accepts Numbers Only',
      MobileNumberIsRequired: 'MobileNumber Is Required',
      codeRequired: 'Please Enter code verfication',
      agreeIsRequired: 'Service Level Policy must be approved.',
      maxLength: 'Code Must be 4 digits',
      MobileNumberLength:
        'Mobile number must be less than 16 digits and more than 9 digits',
      thisFieldShouldHaveMinimumLength: "The phone number must have at least 9 digits.",
      thisFieldShouldHaveMaximumLength: "The phone number must not exceed 16 digits.",
      invalidPhoneNumber: "Please enter a valid mobile number.",
      recaptchaRequired: "Visual Code Required"
    },
    employeeManagement: {
      playerManagement: 'Employee Management',
      employeeList: 'Employee List',
      management: 'Employee',
      employee: 'Management',
      employee1: 'Employee',
      team: 'Team',
      selectTeam: "Select Team",
      teamMember: "Team Members",
      editTeam: "Edit Team",
      actions: "Actions",
      search: 'Search by name or email',
      phonesearch: 'Search by phoneNumber',
      completed: 'Completed',
      inCompleted: 'Incompleted ',
      active: 'active',
      inActive: 'Inactive ',
      All: 'All',
      allCustomers: ' Users Management',
      activeMembers: 'Active Members',
      playerName: 'Employee Name ',
      playerStatus: 'player Status ',
      mobileNumber: 'Mobile Number',
      Email: 'Email',
      country: 'Country',
      accountStatus: ' Account Status',
      emailStatus: ' Email Status',
      Block: 'Block',
      blockStatus: 'Block status',
      Blocked: 'Blocked',
      notBlocked: 'Notblocked',
      Details: 'Account Details',
      edit: 'Edit ',
      delete: 'Delete',
      branch: 'Branch',
      Confirmed: 'Confirmed',
      NotConfirmed: 'Not Confirmed',
      NotFound: 'No data available',
      total: 'total',
      addNew: 'Add New Employee',
      confirmModalTitle: 'Confirmation Change Status',
      activeMessage: 'Do you want to change account status?',
      changeAccountStatus: 'Account Status changed successfully',
      ok: 'OK',
      cancel: 'Cancel',
      branchName: 'Branch Name',
      more: 'More',
      allbranch: 'All Branches',
      createTeam: "Create Team",

    },
    createTeam: {
      createNewTeam: "Create New Team",
      teamName: "Team Name",
      writeTeamName: "Write Team Name",
      addNewMember: "Add New Member",
      username: "Username",
      addBtn: "Add",
      newMembers: "new Members",
      selectMember: 'Select Users',
      selectMembersPlaceholder: 'Select team members',
      cancelAndReturn: 'Cancel and Return',
      saveTeam: 'Save Team',
    },
    emplyeeEditAdd: {
      addEmployee: 'Add New Employee',
      writeName: 'write Name',
      UserRole: 'User Role',
      Employee: 'Employee',
      SystemAdministrator: 'System Administrator ',
      writeTeamName: 'Write Team Name ',
      JobTitle: 'Job Title',
      JobTitleWrite: ' Write JobTitle',
      editEmployee: 'Edit Employee',
      name: 'Employee Name',
      branch: 'Branch Name',
      SelectOption: 'Select Option',
      email: 'Email Address',
      phoneNumber: 'Phone Number',
      save: 'Save',
      cancel: 'Cancel',
      confirmDel: 'Are you sure to delete Employee ?',
      activeMessage: 'Employee Deleted Successfully',
      delete: 'Confirmation Delete Employee',
      addSuccess: 'Employee Added Successfully',
      editSuccess: 'Employee Edited Successfully',
      licenceNo: 'License Number',
      licenceDate: 'License Start Date',
      licenceEndDate: 'License End Date',
      confirmModalTitle: 'Confirmation Delete Branch',
      confirmDelte:
        'When changing the branch, all assigned tasks for this employee will be deleted',
      deleteuccess: 'Branch deleted Successfully',
      confirmDelteBranch: 'Delete Employee Confirmation',
      employeeType: "Employee Type",
      Individual: "Individual",
      team: "Team",
      teamName: "Team Name",


    },
    emplyeeDetails: {
      details: 'Employee Information',
      cancel: 'Back',
    },
    branches: {
      branches: 'Management',
      management: 'Branch',
      branshList: 'Branch List',
      branchesManagement: 'Branches Management',
      branch: 'Branch',
      addNew: 'New Branch',
      search: 'Search by branch name',
      accountStatus: 'Branch Status',
      active: 'Active',
      inActive: 'InActive',
      All: 'All',
      NotFound: 'Not Found',
      name: 'Branch Name',
      status: 'Branch Status ',
      edit: 'Edit',
      delete: 'Delete',
      confirmModalTitle: 'Change branch status',
      activeMessage: 'Are you sure to change branch status?',
      changeAccountStatus: 'Branch status changed successfully',
      branchManager: 'Branch Manager',
      region: 'Region',
      employeeNo: 'Employee Number',
      branchDetails: 'Branch Details',
      branchInfo: 'Branch Information',
      select: 'select',
      BranchOrOffice: 'Branch Or Office'
    },
    branchEditAdd: {
      addBranch: 'Add New Branch',
      editBranch: 'Edit Branch',
      arabicName: 'Branch Name in Arabic',
      WriteArabicName: 'Write Arabic Name',
      englishName: 'Branch Name in English',
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Delete Branch',
      confirmDel: 'Do you Want to Delete Branch ?',
      activeMessage: 'Branch deleted Successfully',
      addSuccess: 'Branch Added Successfully',
      editSuccess: 'Branch Edited Successfully',
      branchInfo: 'Branch Information',
      region: 'Region',
      branchManager: 'Branch Manager',
      employeeNo: 'Number of Employees',
      another: 'Another',
      disableLabel: 'Please Add Employees',
    },
    userDetails: {
      userDetails: 'User Details',
      agentName: 'Agent Name',
      commercialNo: 'Commercial Registration Number',
      employeeNo: 'Number of employees',
      accountTyoe: 'Account Type',
      Establishmentsector: 'Organization Sector',
      currency: 'Currency',
      region: 'Region',
      city: 'City',
      agentAddress: 'Agent address',
      agentEmail: 'Organization Email',
    },
    editProfile: {
      edit: 'Edit',
      profile: 'Profile',
      editProfile: 'Edit Profile',
      personalInfo: 'Personal Information',
      agentInfo: 'Organization Information',
      addSuccess: 'Profile edited Successfully',
      addPasswordSuccess: 'Password edited Successfully',
      ConfirmEmail: 'Confirm Email',
      email: 'Email',
      sendCode: 'Email Confirmation',
      send: 'Send',
      sendemailSuccess: 'Email edited Successfully',
      confirmEmailMessage: 'send the link of reset the password',
      EnterCode: 'Confirmation Code',
      sendemailErrro: 'Code is Wrong',
      editVat: 'Edit Vat',
    },
    contract: {
      contractManagement: 'Contract Management',
      subTitle: '',
      notFound: 'Not Found',
      search: 'Search',
      contractAddress: 'Contract Address',
      plzChoose: 'Choose',
      contractStatus: 'Contract Status',
      All: 'All',
      Active: 'Active',
      Deferred: 'Deferred',
      Finished: 'Finished',
      Canceled: 'Canceled',
      Pending: 'Pending',
      NotFound: 'Not Found',
      startDate: 'Contract Start Date ',
      endDate: 'Contract End Date ',
      user: 'Client',
      branch: 'Branch',
      caseStatus: 'Activation Status',
      contractTitle: 'Contract List',
      addNew: 'New Contract',
      total: 'Total',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change Contract status?',
      contractDetail: 'Contract Details',
      contractInfo: 'Contract Info',
      InActive: 'InActive',
      contractSubject: 'Contract Details and Terms',
      contractTeam: 'Assigned Team',
      remember: 'Notify',
      timeRemain: 'Days Before Contract End Date',
      changeAccountStatus: 'Contract Status changed successfully',
      NotAuth: 'You Are Not Authorized To View Data',
      ApplicationReview: 'Contrat Review',
      day: 'Day',
    },
    packageError: {
      finishEmployee:
        'You have reached the maximum. Please upgrade your package to be able to add.',
      finishBranch:
        'You have reached the maximum number of branches. Please upgrade your package to be able to add a new branch.',
      expired:
        'You have reached the maximum number of employees. Please upgrade your package to be able to add a new employee.',
      invalid:
        'This service is not available in your current package. Please upgrade your package to access this service',
      expiredEmployee: `We would like to inform you that your Agent's package with Qyd has expired. We ask you to contact the Agent to renew the subscription`,
      sorry: 'Sorry !!',
      PackageUpgrade: 'Package Upgrade ',
    },
    issue: {
      issueManagement: 'Case Management',
      issueNo: 'Case Number',
      issueStatus: 'Case Status',
      court: 'Courts',

      ddd: 'Entity',
      issueType: 'Case Type',
      issueTeam: 'Assigned Team',
      teamName: 'Assigned Name ',
      changeAccountStatus: 'Case Status changed successfully',
      issueList: 'Case List',
      addNew: 'New Case',
      All: 'All',
      Individual: 'Individual',
      Special: 'Special',
      Governmental: 'Governmental',
      Active: 'Active',
      UnderConsideration: 'Under Consideration',
      Finished: 'Finished',
      Canceled: 'Canceled',
      Pending: 'Pending',
      Details: 'View',
      edit: 'Edit Details',
      glsat: 'Sessions',
      log: 'Case History',
      issueDetail: 'Case Details',
      issueInfo: 'Case Info',
      mainCourt: 'Courts and quasi-judicial committees',
      subCourt: 'Subsidiary Courts / Subsidiary Committees',
      issueDate: 'Case Registration Date',
      issueEndDate: 'Caes End Date',
      appStatus: 'Application Status',
      Inactive: 'Inactive',
      issueSubject: 'Subject of the Lawsuit',
      PlaintiffRequests: 'Requests of the Lawsuit',
      PlaintiffEvidence: 'Evidence of the Lawsuit',
      defendantRequests: 'Requests of the Defendant',
      defendantEvidence: 'Evidence of the Defendant',
      branchName: 'Branch Name',
      assignedName: 'Assigned Name',
      assignedName2: 'Assigned Type',
      assignedName3: "'Assigned Name'",
      Applicant: 'Plaintiff',
      applicantType: "Applicant's Type",
      PlaintiffName: 'Plaintiff Name',
      PlaintiffType: 'Plaintiff Type',
      DefendantName: 'Defendant Name',
      DefendantType: 'Defendant Type',
      idNo: 'ID/Residence Number',
      nationality: 'Nationality',
      Defendant: 'Defendant',
      attachments: 'Attachments',
      uploadDate: 'Upload Date',
      uploadBy: 'Upload By',
      download: 'Download',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change account status?',
      isDraft: 'Draft',
      details: 'Details',
      issueState: 'Activation Status',
      workDuy: 'Task',
      newSession: 'New Session',
      newWork: 'New Task',
      najez: 'Case Must Be Recorded in Najez To Add It',
    },

    addIssue: {
      clientName: "Write Client Name",
      clientName2: "The capacity of the entity’s representative",
      writeName2: " Choose",
      RepresentativeStatus: 'Representative Status',
      writeHere: "Write Here...",
      writeIDType: "Write ID Type",
      writeIdNo: "Write ID Number",
      clientEmail: "Write email",

      choose: 'Choose',
      chooseContractStatus: 'choose Contract Status',
      chooseClient: 'choose Client',
      Court: 'Court',
      Committee: 'Committee',
      courtType: 'Type of Entities',
      notaddMorefive: 'Maximum employees number assigned is 5',
      Add: 'Add',
      AddTeam: 'Add Team',
      addNewCase: 'Add a new case',
      issueNo: 'Case Number',
      issueStatus: 'Case Status',
      court: 'Courts',
      issueType: 'Case Type',
      chooseIssueType: 'Choose Case Type',

      subIssueType: 'Sub-Case Type',
      chooseSubIssueType: 'Choose Sub-Case Type',

      issueTeam: 'Assigned Team',
      issueList: 'Case List',
      addNew: 'New Case',
      All: 'All',
      Individual: 'Individual',
      Special: 'Private',
      Governmental: 'Governmental',
      Workers: 'Workers',
      Criminal: 'Criminal',
      PersonalConditions: 'PersonalConditions',
      Civilian: 'Civilian',
      Administrative: 'Administrative',
      Commercial: 'Commercial',
      Active: 'Active',
      UnderConsideration: 'Under Consideration',
      Inprogress: 'In progress',
      Finished: 'Completed',
      Pending: 'Under consideration',
      Approval: 'Suspended',
      Canceled: 'Canceled',
      UnderReview: 'Under review',
      Details: 'View',
      edit: 'Edit Details',
      glsat: 'Sessions',
      log: 'Case History',
      issueDetail: 'Case Details',
      issueInfo: 'Case Info',
      mainCourt: 'Judicial Courts ',
      subCourt: 'Sub-tribunals',
      issueDate: 'Case Registration Date',
      appStatus: 'Application Status',
      chooseAppStatus: 'Choose Application Status',

      Inactive: 'Inactive',
      issueSubject: 'Subject of the Lawsuit',
      branchName: 'Branch Name',
      assignedName: 'Assigned Name',
      Applicant: 'Plaintiff',
      applicantType: "Applicant's Type",
      idNo: 'ID/Residence Number',
      nationality: 'Nationality',
      Defendant: 'Defendant',
      attachments: 'Attachments',
      uploadDate: 'Upload Date',
      uploadBy: 'Upload By',
      download: 'Download',
      Plaintiff: 'Plaintiff',
      ApplicationReview: 'Lawsuit Review',
      select: 'Select here',
      select2: 'Select Type',
      NoMaxLength: 'Max Number 50 ',
      onlyNumber: 'Please Enter Numbers Only',
      Client: 'Client',
      ClientType: 'Client Type',
      Other: 'Other',
      Other2: 'Other',
      returnText: 'Reset Text',
      SaveAndContinue: 'Save And Continue',
      Save: 'Save',
      BranchName: 'Branch Name',
      BranchName2: 'Branch Name',
      BranchName3: 'Write branch name',
      BranchName4: 'Choose branch name',
      NotFoundEmployees: 'Not Found Employees',


      Back: 'Back',
      Cancel: 'Cancel',
      delete: 'Delete',
      PlaintiffType: 'Plaintiff Type',
      IndividualType: 'Individual',
      GovernmentEntity: 'Government Entity',
      PrivateEntity: 'Private Entity',
      ApplicantsName: "Applicant's Name",
      IDType: 'ID Type ',
      PersonalID: 'Personal ID',
      Passport: 'Passport',
      DefendantType: 'Defendant Type',
      Evidence: 'Evidence and Data for the Case ',
      Category: 'Category / Classification',
      Attachment: 'Attachment ',
      Document: 'Document',
      UploadDate: 'Upload Date',
      SupportingDocuments: 'Supporting Documents',
      Name: 'Uploader name ',
      Case: 'Case',
      upload: 'Upload',
      fileType: 'File Type',
      caseAddSuccess: 'Case Added Successfully ',
      caseEditSuccess: 'Case Edited Successfully ',
      cancel: 'Cancel',
      save: 'Save',
      caseAttachment: 'Lawsuit Attachments',
      reviewTitle: 'Lawsuit Review',
      reviewSubTitle: 'Please review the lawsuit before approving it',
      askEdit: 'Are you sure you want to edit this case?',
      reason: 'Edit Reason',
      atLeastOne: 'Add One Name At least',
      haveAssigned: 'Can’t Add The Same Employee Twice',
      name: 'Name',
      fileManager: 'Case File Manager',
      editSuccess: 'Assigned Team Added Successfully',
      editText: 'Edit Text',
      GeneralCourt: 'General Courts / General Judiciary',
      MinistryOfHumanResources: 'Amicable settlement',
      AdministrativeCourt: 'Administrative Courts / Administrative Judiciary',
      Representative: 'The Public Prosecution',
      PoliceStation: 'Police Stations',
      Prisons: 'Prisons',
      otherCourtType: 'Type of Entities (Other)',
      otherCommittee: 'Committee (Other)',
      otherCourt: 'Main Court (Other)',
      otherSubCourt: 'Sub Court (Other)',
      confirmDelteBranch:
        'Are you sure about changing the branch and removing the assigned team?',
    },
    addClient: {
      copydone: 'Client Data Has Been SuccessfullyCopied',
      CommercialRegistrationNumber: 'Commercial Registration Number',
      copy: 'Client Data Copied',
      actions: 'Actions',
      newClient: 'New Client',
      Individual: 'Individual',
      Special: 'Special',
      business: 'Business Sector',
      Active: 'Active',
      NotActive: 'Inactive',
      clientName: 'Client Name',
      clientEmail: 'Email Address',
      clientPhoneNumber: 'Mobile Number',
      clientStatus: 'Client Status',
      commericalName: 'Commerical Name',
      commericalNumber: 'Commerical Register',
      addSuccessfuly: 'Client added  successfully',
      editSuccessfuly: 'Client edited successfully',
      CommirecalNoMaxLength: 'Commercial Register Must Be 10 Digits',
      EmailPattern: 'Please enter a valid email address.',
      natioality: 'Nationality',
      nationalityIdRequired: 'Please Choose Nationality',
      nationalIdRequired: 'Please Choose ID Type',
      IDType: 'ID Type ',
      PersonalID: 'Personal ID',
      Passport: 'Passport',
      idNo: 'ID Number',
      NationalIdentity: 'National Identity',
      RegularResidence: 'Regular Residence',
      GulfNationalIdentity: 'Gulf National Identity',
      WorkAndMobilityCard: 'Work And Mobility Card',
      branchName: 'Branch Name',
      branchIdRequired: 'Please choose Branch',
      uploadId: 'National ID Card Copy',
      uploadAddress: 'National Address Certificate',
      address: 'National Address',
      birthDay: 'Birthday Date',
      attachment: 'Attachment',
      classification: 'Classification',
      classificationRepeated: 'This Document Has Been Attached Before',
      attachmentRequired: 'You Must Add The Required Documents',
      download: 'Download',
      Accommodation: 'Accommodation',
      PersonalId: 'Personal ID',
      Visa: 'Visa',
      Other: 'Other',
      BoardDirector: 'Board Director',
      CEO: 'CEO',
      LegalAdvisor: 'Legal Advisor',
      Manager: ' Manager',
      Accountant: 'Accountant'
    },
    client: {
      clientManagement: 'Client Management',
      clientManagement2: 'Write Client Management',
      representativeName: 'representative Name',
      representativeName2: 'Write representative Name',
      clientName: 'Client Name',
      clientEmail: 'Email Address',
      clientStatus: 'Client Status',
      clientSearch: 'Client Name or Email',
      All: 'All',
      Active: 'Active',
      NotActive: 'Inactive',
      clientList: 'Client List',
      addNew: 'New Client',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change client status?',
      Details: 'View',
      edit: 'Edit Details',
      clientDetails: 'Client Details',
      clientType: 'Client Type',
      Individual: 'Individual',
      Special: 'Special',
      Governmental: 'Governmental',
      clientPhone: 'Phone Number',
      Back: 'Close',
      editbtton: 'Edit',
      clientInfo: 'Client Information',
      attachment: 'Attachments',
    },
    workDuty: {
      workDutyManagement: 'Task Management',
      projectManagement: 'Project Management',
      accountingSummary: "Accounting Summary",
      workDutyName: 'Task Name',
      search: 'Search',
      plzChoose: '',
      workDutyStatus: 'Task Status',
      All: 'All',
      Active: 'Active',
      Deferred: 'Deferred',
      Finished: 'Finished',
      Canceled: 'Canceled',
      Pending: 'Pending',
      NotFound: 'Not Found',
      startDate: 'Task Start Date ',
      endDate: 'Task End Date ',
      user: 'Client',
      caseStatus: 'Activation Status',
      workDutyTitle: 'Task List',
      addNew: 'New Task',
      total: 'Total',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change Task status?',
      workDutyDetail: 'Task Details',
      workDutyInfo: 'Task Info',
      InActive: 'InActive',
      changeAccountStatus: 'Task status changed successfully',
      workDutyType: 'Task Type',
      general: 'General',
      relatedCase: 'Related to case',
      Subject: 'Task Details',
      caseNo: 'Related Case Number',
      caseAddSuccess: 'Task Added successfully',
      assignedSuccess: 'Task Team Added successfully',
      caseEditSuccess: 'Task is updated successfully',
      endDateValidation: 'End Date Should Not Be Before Start Date',
    },
    AddEditcontract: {
      contractManagement: 'Contract Management',
      contract: 'Contract',
      subTitle: '',
      search: 'Search',
      contractAddress: 'Contract Address',
      plzChoose: 'Choose',
      contractStatus: 'Contract Status',
      All: 'All',
      Active: 'Active',
      Deferred: 'Deferred',
      Finished: 'Finished',
      Canceled: 'Canceled',
      Pending: 'Pending',
      NotFound: 'Not Found',
      startDate: 'Contract Start Date ',
      endDate: 'Contract End Date ',
      user: 'Client',
      caseStatus: 'Activation Status',
      contractTitle: 'Contract List',
      addNew: 'New Contract',
      total: 'Total',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change account status?',
      contractDetail: 'Contract Details',
      contractInfo: 'Contract Info',
      InActive: 'InActive',
      contractSubject: 'Contract Details and Terms',
      contractTeam: 'Assigned Team',
      remember: 'Notify',
      timeRemain: 'Days Before Contract End Date',
      notifyAssigned: 'Assigned Notify',
      contractAddSuccess: 'Contract Added Successfully',
      contractEditSuccess: 'Contract Edited Successfully',
      Days: 'Days',
      endAfterStart: 'End Date must be after Start Date',
      contractName: 'Enter Contract Address',
      contractTerm: 'Enter Contract Terms',
      enterStartDate: 'Enter Start Date',
      enterEndDate: 'Enter End Date',
      enterDetails: 'Write Contract Details',
      contractDetails: "Financial details of the contract",
      anyFees: "Are there any fees?",
      yes: "Yes",
      no: "No",
      ContractValue: "Contract Value (Fees)",
      deposit: "Deposit Payment Amount",
      FinalAmount: "Final Payment Amount",
      installments: "Installments",
      installmentNumber: "Installment Number",
      installmentAmount: "Installment Amount",
      reason: "Reason",
      ContractValuePlaceholder: 'Enter the contract value (fees)',
      Currency: 'SAR', // Saudi Riyal
      depositPlaceholder: 'Enter the deposit value',
      amountOption: 'Amount SAR',
      percentageOption: 'Percentage %',
      FinalAmountPlaceholder: 'Enter the final amount',
      installmentNumberPlaceholder: 'Enter the installment number',
      installmentAmountPlaceholder: 'Enter the installment amount',
      reasonPlaceholder: 'Example: Social Initiative',

    },
    addWorkDuty: {
      Add: 'Add',
      workDuty: 'Task',
      edit: 'Edit',
      workDutyDetail: 'Task Details',
      workDutyTeam: 'Assigned Team ',
      All: 'All',
      Active: 'Active',
      Deferred: 'Deferred',
      Finished: 'Finished',
      Canceled: 'Canceled',
      Pending: 'Pending',
      NotFound: 'Not Found',
      startDate: 'Task Start Date ',
      endDate: 'Task End Date ',
      workDutyName: 'Task Name',
      workDutyNameAnother: 'Task Name (Other)',
      WriteworkDutyNameAnother: 'Write workDuty Name Another',
      workDutyStatus: 'Task Status',
      workDutyType: 'Task Type',
      general: 'General',
      relatedCase: 'Related to case',
      Subject: 'Task Details',
      WriteSubject: 'Write Subject',
      caseNo: 'Related Case Number',
      ApplicationReview: 'Task Review',
      reviewSubTitle: 'Please review the Task before approving it',
    },
    sitting: {
      Scheduled: 'Scheduled',
      Postponed: 'Postponed',
      sittingManagement: 'Sessions Management',
      CourtSessions: 'Court Sessions',
      CourtSession: 'Court Session',
      CourtSessionDetails: 'Court Session Details',
      CourtSessionManagement: 'Court Sessions Management',
      CourtSessionName: 'Court Session Name',
      CourtSessionTitle: 'Court Session Title',
      CourtSessionplace: 'Court Session Place',
      sittingStatus: 'Court Session Status',
      sittingLink: 'Court Session Link',
      startDate: 'Court Session Start Date',
      startTime: 'Court Session Start Time',
      list: 'Court Sessions List',
      addNew: ' New Court Session',
      report: 'Court Session Report',
      sittingOfCase: 'Conect With Case',
      reportAddress: 'Report Address',
      reportDate: 'Report Date',
      reportSubject: 'Report Subject',
      pdfView: 'View Report in PDF',
      activeMessage: 'Do you want to change Court Session status?',
      confirmModalTitle: 'Confirmation',
      send: 'Send report to client',
      sendSuccess: 'Report is sent successfully',
      log: 'Court Session history',
      caseAddSuccess: 'Court Session Added Successfully ',
      caseEditSuccess: 'Court Session Edited Successfully ',
      askEdit: 'Are you sure you want to edit this Court Session?',
      changeAccountStatus: 'Court Session Status changed successfully',
      CourtSessionNumber: 'Court Session Number',
      courtType: 'Court Type',
      sittingType: 'Court Session Type',
      Deferred: 'Deferred',
      All: 'All',
      Online: 'Online',
      Incourt: 'Incourt',
      addReport: 'Report Added Successfully',
      noAssigned: 'No Data of Assigned Team',
      noReport: "Don't have Court Session Report",
      noAttachment: "Don't have attachment",
      courts: 'Courts',
      Committees: 'Committees',
      timeRemain: 'Days Before Court Session End Date',
      Typehere: 'Type here',
      ApplicationReview: 'Court Session Review',
      Finished: 'Finished',
      Active: 'Active',
    },
    contactUs: {
      contact: 'Contact Us',
      label:
        'We answer your inquiries, enhance our services based on your suggestions, and address your challenges to ensure an organized and easy legal management experience.',
      purpose: 'Goal of Communication',
      Inquiry: 'Inquiry',
      Suggestion: 'Suggestion',
      Complaint: 'Complaint',
      name: 'Name',
      email: 'Email',
      message: 'Text Message',
      send: 'Send',
      MessageType: 'Message Type',
      SuccessfulMessage: 'Your Message sent successfully',
      contactNumber: "Phone",
      supportMail: "Support Mail",
      infoMail: "Info Mail",
      address: "Address"

    },
    privacy: {
      title: 'Privacy Policy',
      label:
        'You can use the platform and benefit from it without revealing your identity or disclosing any details that could be used to identify you as a specific individual.',
      head1: 'Introduction',
      body1:
        'At "Qyd," we are committed to protecting your privacy and ensuring the security of your personal data. This Privacy Policy explains how we collect, use, and protect the information you provide when using the "Qyd" system services. Please read this policy carefully to understand our privacy practices.',
      head2: 'Information Collection',
      body20:
        'We collect information in various ways when you use the "Qyd" system, including:',
      body21:
        '- Personal Information: Name, email address, phone number, and other basic information.',
      body22: '- Account Details: Such as username and password.',
      body23:
        '- Case-related Information: Case data and legal documents that you enter or upload to the system.',
      body24:
        '- Technical Information: Device type, operating system, browser type, IP address.',
      body25:
        '- Usage Information: How you use the system and interact with our services.',
      head3: 'Use of Information',
      body30: 'We use the information we collect for the following purposes:',
      body31:
        "- Providing Services: Enabling you to use the 'Qyd' system and manage cases and documents, improving and developing our services to meet your needs.",
      body32:
        '- Communication: Sending notifications and messages related to your account or system updates, responding to your inquiries, and providing technical support.',
      body33:
        '- Security: Protecting your account and personal information from unauthorized access or illegal use, monitoring, and analyzing suspicious activities to prevent fraud and breaches.',
      body34:
        '- Legal Compliance: Complying with legal and regulatory requirements.',
      head4: 'Information Protection',
      body40:
        'We are committed to taking all necessary security measures to protect your personal information, including:',
      body41:
        '- Data Encryption: Using encryption technologies to protect data transmitted and stored.',
      body42:
        '- Limited Access: Restricting access to personal information to authorized employees only.',
      body43:
        '- Monitoring and Control: Regularly monitoring systems to detect and address any security threats.',
      head5: 'Information Sharing',
      body50:
        'We do not share your personal information with third parties except in the following cases:',
      body51: 'With Your Consent:',
      body52: '- When you give us explicit consent to share the information.',
      body53:
        '- Service Providers: With service providers who assist us in operating the system and providing our services, provided they comply with our privacy policies.',
      body54:
        '- Legal Purposes: When disclosure of information is necessary to comply with laws, legal procedures, or government requests.',
      head6: 'Your Rights',
      body60:
        'We respect your rights regarding your personal information, including:',
      body61:
        '- Right to Access: You can request access to the personal information we hold about you.',
      body62:
        '- Right to Rectification: You can request correction or updating of your personal information if it is inaccurate or incomplete.',
      body63:
        '- Right to Deletion: You can request the deletion of your personal information in certain cases, such as when the information is no longer necessary for the purposes for which it was collected.',
      body64:
        '- Right to Object: You can object to the processing of your personal information in certain cases, such as direct marketing.',
      head7: 'Changes to the Privacy Policy',
      body7:
        'We may update the Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and sending a notice via email. We recommend reviewing the Privacy Policy periodically to stay informed about how we protect your information.',
      head8: 'Contact Us',
      body80:
        'If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us via:',
      body81: ' - Email: ',
      body82: '- Phone: ',
      body83: '- Address:',

      body84:
        'Thank you for trusting "Qyd." We are committed to maintaining your privacy and the security of your personal information.',
    },
    question: {
      title: 'Asked Questions',
      label:
        "If you have any further questions or need additional assistance, don't hesitate to",
      contact: ' reach out to us. ',
      label2:
        'We are here to serve you and ensure a smooth and enjoyable experience with the "Qyd" system.',
    },

    home: {
      qyd: 'QYD',
      demo: 'Request a demo',
      // head: 'Smart Legal Solutions',
      // body: 'We streamline your legal work with innovative technology to facilitate the management of legal cases and procedures, offering extensive storage spaces and high privacy on private servers within Saudi Arabia. This saves time, reduces costs, and enhances your clients trust.',
      // join: 'Subscribe Now Without Fees',
      // firstHead: 'Who Do We Target?',
      firstBody:
        'We offer specialized legal consultations and comprehensive services to ensure the protection of our clients rights.',
      individual: 'Individuals',
      laywer: 'Lawyer',
      head: 'Transforming Legal Operations into Success.',
      head2: 'Qyd, your smart partner',
      body1: 'We limit your business with innovative technologies to raise the efficiency of legal and administrative work in one platform,',
      body2: ' which reduces time, saves costs, and increases the confidence of your clients.',
      join: 'Join now for free',
      getQyd: 'اشترك الآن بتجربة دون رسوم ',
      systemInte: 'Your system  Integrated',
      firstHead: 'Discover our Qyd Services',
      // firstBody: 'We offer specialized legal consultations and comprehensive services to ensure the protection of our clients rights.',
      individual1: 'Case Management',
      individual2: 'Task Management ',
      individual3: 'Session Management ',
      individual4: ' Contract Management ',
      individual5: 'Simplifying case management',
      individual6: 'Time-saving through automation',
      individual7: 'Complete control over schedules and documents',
      individual8: 'Enhancing privacy and security',

      individual9: 'Client management',
      individual10: ' Document management',
      individual11: ' Employee management',
      individual12: ' Branch management',
      individual13: '  Contract management',
      individual14: ' Agency management',
      individual15: ' Head office management',
      laywer1:
        'We collect your cases in one control panel and record each case in all its details, with the ability to update them easily, so you can focus on monitoring your progress.',
      laywer2:
        "Manage your employees' tasks through the platform. You will automatically send them reminder notifications of future tasks and appointments, to follow the progress of work and task completion from your control panel. ",
      laywer3:
        'We organize the dates of judicial hearings by scheduling them and reminding the assigned employees of the details and requirements associated with the cases to pursue the interests of clients. ',
      laywer4:
        "With organized digital files, manage your employees' contracts, active client agreements, and your firm's partner contracts all in one place, using advanced and secure storage tools.",
      laywer5:
        'Our tools facilitate organizing all cases and documents in one place with a simple interface, saving time and effort in search operations.',
      laywer6:
        'Automating routine tasks such as reminders, session tracking, and report generation allows you to focus more on your legal outcomes',
      laywer7:
        'Managing meeting and session schedules with reminder notifications, and accessing documents easily from any device at any time.',
      laywer8:
        'Advanced encryption techniques ensure the protection of legal documents, with full control over who can access them to maintain confidentiality.',

      laywer9:
        "We save your customers' data by recording them in an organized list for easy management and access, ensuring effectiveness in communication and service provision.  ",
      laywer10:
        'We provide you with sufficient and secure space for your clients’ documents in private storage servers inside Saudi Arabia, with the ability to classify them through advanced technical tools. ',
      laywer11:
        'Register your employees according to the number of users you have, and follow up on assigning them legal work through the platform with organizational procedures that help them achieve.',
      laywer12:
        'To increase the administrative organization of your office with multiple branches, you can customize a control panel for each branch to manage it independently from other branches. ',
      laywer13:
        'With organized digital files, manage your employee contracts and those of your existing clients and office partners in one place, with advanced and secure storage tools. ',
      laywer14:
        'We organize the agencies that have been delegated to you by clients, according to their general type, or specific to a particular case, with the start and end dates for easy search and access. ',
      laywer15:
        'Your digital office is with you everywhere. You can manage your cases, appointments, and documents all from one control panel at any time. ',

      whyQydTheBest:
        'Why partner signed up for the agreement for your business ?  ',
      getStarted: 'Get Started →',
      laywerBody: 'Represents individuals or legal entities before the courts.',
      consultant: 'Legal Consultant',
      consultantBody: 'Provides legal advice and guidance.',
      entity: 'Entities',
      representive: 'Official Representative',
      LegalManagement: 'Legal Management',
      representiveBody: 'Represents the legal entity in official procedures.',
      law: 'Law Firms',
      lawBody: 'Institutions providing comprehensive legal services.',
      legal: 'Corporate Legal Departments',
      legalBody: "We handle the company's legal interests.",
      services: 'Subscribe now for a free trial  ',
      howWork: 'How we work?',
      step1:
        'Saving business operational costs through complete automation of the business system.',
      step2:
        'A comprehensive and integrated system for use anytime and anywhere. ',
      step3:
        'A storage location for high-security reading technologies inside Saudi Arabia.',
      step4: 'Help center to answer questions and usage problems.',
      step5:
        'Updates that include only the strength of performance and service sustainability.',
      Annual: 'Annual',
      Monthly: 'Monthly',
      prominentHead: 'Why Qyd as the perfect partner for your business?',
      sol: 'Our Solutions',
      mangTime: "Manage your business.",

      prominentBody: 'We offer you sustainable solutions ',
      prominentText1: 'Scattered documents, limited storage space.',
      prominentText1Body:
        'Save documents and documents in sufficient and secure storage space. ',
      prominentText2:
        'A schedule full of tasks and appointments. ,the solution',
      prominentText2Body: 'Custom scheduling and reminder system.',
      prominentText3: 'Poor communication between your employees.',
      prominentText3Body: 'Direct communication and continuous follow-up.',
      prominentText4:
        'Frequent movement with the need to follow up on office work.',
      prominentText4Body:
        'A digital control panel that is managed at any time and from anywhere. ',
      prominentText5: 'A flaw in managing your customer data.',
      prominentText5Body: "Record your customers' data in an organized list.  ",
      step1Content:
        'Maintain a clear overview of all ongoing legal matters | Set and monitor important deadlines with ease | Maintain a clear overview of all ongoing legal matters',
      step2Content:
        'Maintain a clear overview of all ongoing legal matters | Set and monitor important deadlines with ease | Maintain a clear overview of all ongoing legal matters',
      step3Content:
        'Maintain a clear overview of all ongoing legal matters | Set and monitor important deadlines with ease | Maintain a clear overview of all ongoing legal matters',
      servicesBody:
        'Take advantage of digital regulatory services to ensure the efficiency of your legal operations.',
      section1:
        'We manage all your cases in a single dashboard, with the ability to update details, link files, and easily schedule sessions.',
      section2:
        'Track the progress of your tasks with reminder notifications for upcoming deadlines, allowing you to monitor your work from your dashboard.',
      section3:
        'We provide ample and secure space for your clients documents with complete confidentiality, stored on private servers within Saudi Arabia.',
      selectPlan: 'Subscribe now for a free trial ',
      packges: 'Packages',
      planDesc: "Enjoy full access to the platform's services for 14 days",
      qydServices: 'QYD services',
      more: 'More Services from Qyd',
      legalManagement: 'Why Choose QYD?',
      FAQ1: 'Have a question in your mind ?',
      FAQ2: 'Answers to your questions ',
      Q1: 'Can I access QYD services from anywhere? ',
      Q2: 'How is my data saved and secured in the QYD system? ',
      Q3: 'What happens to my data if I decide to unsubscribe? ',
      Q4: 'How can I contact the Help Center? ',
      Q5: 'Does the Qayed system support linking with other digital systems? ',
      Q1Desc:
        'Qyd stores all data on private servers within Saudi Arabia, using top-level encryption and regular backups to ensure maximum security.',
      Q2Desc:
        'Qyd stores all data on private servers within Saudi Arabia, using top-level encryption and regular backups to ensure maximum security.',
      Q3Desc:
        'Qyd stores all data on private servers within Saudi Arabia, using top-level encryption and regular backups to ensure maximum security.',
      Q4Desc:
        'Qyd stores all data on private servers within Saudi Arabia, using top-level encryption and regular backups to ensure maximum security.',
      Q5Desc:
        'Qyd stores all data on private servers within Saudi Arabia, using top-level encryption and regular backups to ensure maximum security.',
      legalManagementLabel:
        'We are committed to professionalism and confidentiality in all our dealings.',
      Staytuned: 'Stay tuned for updates',
      StaytunedDesc:
        "At Qyd we're excited to integrate your work. Sign up to be alerted to product updates.",
      cardTitle1: 'Support',
      email: 'Email',
      submit: 'Submit',
      inputFooter: 'To learn how Qyd handles your information,',
      inputFooter2: 'please see our ',
      inputFooter3: 'privacy policy',
      cardBody1: '24/7 customer support.',
      cardTitle2: 'Security',
      cardBody2: 'Top-notch security for all your transactions.',
      cardTitle3: 'Data Backup:',
      cardBody3: 'Regular backups to ensure your data is safe.',
      cardTitle4: 'Storage',
      cardBody4: 'Unlimited storage for your documents.',
      header5: 'Subscribe now for a free trial.',
      label5:
        'Flexible packages that suit your needs and organize your legal work with a free trial.',
      WhoTarget: 'Who we target?',
      Entities1: 'The individual sector',
      Entities2: 'The sector of entities',
      Lawyer: 'Lawyer',
      LegalAdvisor: 'Legal advisor',
      InHouse: 'In-house legal department ',
      Statutory: 'Statutory representative',
      Law: 'Law firm',
      meetAllNeeds: 'We meet all the needs of different areas',
      window: 'Your window to manage your legal affairs',
      window2: "We enhance your business with digital transformation.",

      Lawyer1: 'Licensed lawyer',
      Lawyer2: 'Legal Consultant',
      Lawyer3: 'Trainee Lawyer',
      Lawyer4: 'Legal researche',

      saveUpTo: 'Save up to 30%',
      //section 2 slider
      section2Slider: {
        name1: "Facilitating case management",
        name2: "Saving time by automating processes",
        name3: "Full control over appointments and documents",
        name4: "Maintaining privacy and security",
        name5: "Simplifying complex legal processes",
        name6: "Enhancing collaboration among employees",
        name7: "Accurate monitoring of governance management",
        name8: "Integration with other systems",


        body1: "With Qeyd tools, organize all cases and documents in one place with an easy-to-use interface, saving you time and effort in managing your work.",
        body2: "Automating routine tasks like reminders, session follow-ups, and report creation allows you to focus more on your legal outcomes.",
        body3: "Manage meeting and session schedules with reminder notifications, and access documents easily from any device, anytime.",
        body4: "Advanced encryption technologies ensure the protection of legal documents, with full control over access to maintain confidentiality.",
        body5: "Automation and analysis tools reduce the time spent on case management, contract review, and dispute management, providing accurate reports to enhance performance.",
        body6: "Real-time document sharing and information updates on a unified platform for all employees, enhancing teamwork and reducing errors.",
        body7: "We help you monitor compliance, policies, and procedures to ensure the highest standards of legal governance are applied.",
        body8: "The system seamlessly integrates with enterprise resource planning (ERP) systems, facilitating data flow between departments without complexity.",
      }
    },
    footer: {
      aboutQyd: 'Qyd Information Technology',
      ryad: 'Riyadh, Saudi Arabia.',
      contactEmail: 'Contact email:',
      contactNumber: 'Sales number:',
      QuickLinks: 'Quick Links',
      privacy2: 'Cancellation and returns policy.',
      address:
        'National Address: King Abdullah Road - Al-Mughrizat District - Riyadh - Kingdom of Saudi Arabia - 2604-2039',
      links: {
        home: 'Home',
        aboutQyd: 'About Qyd',
        services: 'Services',
        packages: 'Packages',
      },
      StayConnected: 'Location',

      whyQyd: 'Why QYD',
      privacy: 'Privacy And Policy',
      terms: 'Terms And Conditions',
      slp: 'Service Level Policy',
      question: 'Asked Questions',
      contact: 'For communication',
      copyright: '© 2024 QYD Information Technology',
    },
    attorny: {
      attornyManagement: ' Power of Attorney Management',
      pleaseSelectPackage: 'please Select Package',
      attornies: 'Power of Attorney',
      CheckInsertData: ' Please Check Insert Data',
      subTitle: '',
      search: 'Search',
      attornyNumber: 'Number',
      enterAttornyNumber: 'Type Number',
      attornyAddress: 'Address',
      enterAttornyAddress: 'Enter Address',
      plzChoose: 'Choose',
      attornyStatus: 'Status',
      enterAttornyStatus: 'Enter Status',

      All: 'All',
      Active: 'Active',
      Deferred: 'Deferred',
      Finished: 'Finished',
      PartiallyDissolved: 'Partially Dissolved',
      Canceled: 'Canceled',
      Pending: 'Pending',
      NotFound: 'Not Found',
      startDate: 'Start Date ',
      endDate: 'End Date ',
      user: 'Client',
      caseStatus: 'Activation Status',
      attornyTitle: 'Power of Attorney List',
      addNew: 'New Power of Attorney',
      total: 'Total',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change Attorney status?',
      attornyDetail: 'Power of Attorney Details',
      attornyInfo: 'Power of Attorney Info',
      InActive: 'InActive',
      attornySubject: 'Power of Attorney Details and Terms',
      attornyTeam: 'Assigned Team',
      remember: 'Notify',
      timeRemain: 'Days Before Attorney End Date',
      changeAccountStatus: 'Power of Attorney Status changed successfully',
      NotAuth: 'You Are Not Authorized To View Data',
      attorny: 'Power of Attorney',
      enterStartDate: 'Enter Start Date',
      enterEndDate: 'Enter End Date',
      enterDetails: 'Write Power of Attorney Details',
      ApplicationReview: 'Power of Attorney Review',
      reviewTitle: 'Application review',
      reviewSubTitle: 'Please review the Power of Attorney before approving it',
      caseAddSuccess: 'Power Of Attorney Added Successfully',
      TeamddSuccess: 'Team dd Success',
      TeamEditSuccess: 'Team Edit Success',
      caseEditSuccess: 'Power Of Attorney Edited Successfully',
      NameOfTeamExist: 'Name Of Team Exist',
      MustAddAtLeastOneItem: 'Must Add At Least One Item'
    },
    payments: {
      free: 'Free',
      payment: 'Payment and confirmation',
      description: 'description',
      paymentMethod: 'payment Method',
      electronicPayment: 'Electronic Payment',
      bankTransfer: 'Bank Transfer',
      buyPackage: 'Buy Package',
      PaymentByReceipts: 'Payment By Receipts',
      PaymentReceipts2: ' Payment List',
      formattedAmount: 'formatted Amount',
      paymentMoyasarDescrption: 'The package fee is paid through',
      paymnetTitle: 'Payment List',
      invoiceNo: ' Invoice Number',
      invoiceStatus: ' Invoice Status',
      paymentWayPaid: 'paymentWayPaid',
      invoiceDate: ' Invoice Date',
      endDate: 'Package End Date',
      invoiceTime: 'Invoice Time',
      packageName: 'Package Name',
      invoiceValue: ' Invoice Value',
      viewInvoice: 'View Invoice',
      downloadReceipt: 'Download Receipt',
      ViewRejection: 'View the reason for rejection',
      invoicePayment: 'Invoice Payment',
      rejectionReason: 'Reason for Rejection',
      canPayment:
        'You can enter the transfer details to proceed with the payment',
      paymentNotice: "Payment notice",
      uploadPhoto: "A copy of the receipt must be attached after the transfer in order for the application to be accepted",
      uploadPhoto2: "Attach a copy of the receipt",
      ibanNo: 'IBAN Number',
      raydBank: "Ryad Bank",
      accountTransfer: "Account number for transfer from Riyad Bank",
      copy: "Account number copied successfully",

      attachReceipt: 'Attatch Reciept',
      clientName: 'Client Name',
      fromDate: 'From Date',
      toDate: 'To Date',
      requestStatus: 'Request Status',
      invoiceApprovement: 'Approve Receipt',
      canApproveOrREject:
        'You can approve or reject the receipt with a reason for rejection',
      rejectReceipt: 'Reject Receipt',
      acceptReceipt: 'Approve Receipt',
      All: 'All',
      NotPaied: 'Unpaid',
      WaitingForApproval: 'Awaiting Approval',
      Paied: 'Paid',
      Rejected: 'Rejected',
      Cancelled: 'Cancelled',
      sar: 'SAR',
      reasonValidation: 'Please Add Rejection Reason',
      changeSuccessfully: 'Receipt Approved successfully',
      valueAddedTaxng: ' value Added Taxng',
    },
    upgradePackage: {
      upgradePackage: 'Upgrade Packages',
      accountType: 'Account Types',
      LawyerOffice: 'Lawyer Office',
      RegularRepresentative: 'Regular Representative',
      Advisor: 'Advisor',
      all: 'All',
      ThreeMonths: 'Three Months',
      SixMonths: 'Six Months',
      Year: 'Year',
      Month: 'One Month',
      employees: 'Employees',
      branches: 'Branches',
      defaultText:
        'This Package include ( Client Management- Contract Management ).',
      SittingManagement: 'Sitting Management',
      BranchManagement: 'Branch Management',
      AgencyManagement: 'Agency Management',
      LawsuitManagement: 'Lawsuit Management',
      TaskManagement: 'Task Management',
      choosePackage: 'Subscribe Now',
      contactUs: 'Contact Us',
      contactUs2: "Design your package",

      currentPackage: 'Current Package',
      discount: 'Discount',
      invoiceDetails: 'Invoice Details',
      invoiceDate: 'Invoice Creation Date',
      durationPackage: 'Subscription Duration',
      clientName: 'Client Name',
      serviceName: 'Service Provider Name',
      agentAddress: 'Agent Address',
      licenseNumber: 'Commercial Registration Number',
      taxNumber: 'Tax Number',
      taxInvoice: 'Tax Invoice',
      serviceCost: 'Service Cost',
      discountRate: 'Discount Rate',
      taxRate: 'Value Added Tax',
      priceAfterDiscount: 'Price After Discount',
      total: 'Total',
      pay: 'Payment',
      pay2: "Online Payment",
      invoicePayment: 'UnPaid Invoice',
      thanks: 'Thank you',
      boughtpackageSuccess: 'bought Package Success',
      MaxNumerCountOfPackage: 'The package cannot be purchased because there are more cases, contracts, or agencies than the maximum number of additions in this package.',
      branchEmployee:
        'You have exceeded the maximum limit for the number of employees and branches. Please delete some to be able to subscribe to the new package.',
      paymentSend:
        'The receipt has been sent to the admin and is under review for activating the package.',
      payMessage:
        'This package contains a previous invoice. You can’t add another invoice to the package. Please review the invoices',
      downloadLink: 'Download from this Link',
      uploadFileFirst: 'Please Upload your receipt',
      Unlimited: "Unlimited users.",
      CustomerManagement: "Customer management.",
      ContractManagement: "Contract management.",
      projectManagement: 'Project Management',
      accountingSummary: 'Accounting Summary'

    },

    package: {
      Zero: 'Zero',
      Seven: '7 Days',
      list: 'Packages List',
      packageManagement: 'Packages Management',
      packageName: 'Package Name',
      return: 'Return',
      Search: 'Search',
      packagePrice: 'Package Price',
      discount: 'Discount',
      packageStatus: 'Package Status',
      packageDetails: 'Package Details',
      packageEdit: 'Package Edit',
      delete: 'Delete',
      addPackage: 'Add Package',
      addCustomizeFreeTrialPackage: 'Add Customize Free Trial Package',
      accountType: 'Account Type',
      lawyerOffice: 'Lawyer Office',
      CustomizedPackage: 'Customized Package',
      GloablPackage: 'Gloablly Package',
      PackageType: 'Package Type ',
      regularRepresentative: 'Regular Representative',
      advisor: 'Advisor',
      packageNameAr: 'Package NameAr',
      packageNameEn: 'Package NameEn',
      disCountRate: 'DisCountRate',
      price: 'Price',
      maxBranchNo: 'Max BranchNo',
      maxEmployeeNo: 'Max EmployeeNo',
      durationPackage: 'Duration Package',
      select: 'Select',
      ThreeMonths: 'ThreeMonths',
      Month: 'Month',
      SixMonths: 'SixMonths',
      Year: 'Year',
      fourteenDays: 'fourteen Days',
      TwentyOneDays: 'Twenty One Days',
      ThirtyDays: 'Thirty Days ',
      SixtyDays: 'Sixty Days ',
      NinetyDays: 'Ninety Days',
      OneHundredEightyDays: ' One Hundred Eighty Days',
      TwoHundredSeventyDays: 'Two Hundred Seventy Days',
      ThreeHundredSixtyDays: 'Three Hundred Sixty Days',
      packageConditions: 'Package Conditions',
      Desc: 'This package includes (Customer management - Contract management ). You can choose additional features of the package: -',
      inEnglish: 'The text in this field must be in English',
      inArabic: 'The text in this field must be in Arabic',
      Inactive: 'Inactive',
      All: 'ALL',
      Active: 'Active',
      changeConfirmation: 'Change Confirmation',
      changeText: 'Are you sure you want to change the status?',
      editTostar: 'Package updated successfully',
      changeToggleTostr: 'Package status changed successfully',
      AddedSuccessfully: 'Package added successfully',
      viewPackage: 'Package Details',
      discountDes: 'DisCountRate',
      priceDes: 'Price',
      ValueAddedTax: 'Value Added Tax',
      packageDetailsTitle: "Ease of increasing the number of users and expanding branches.",
      packageDetailsTitle2: "It is possible to increase the number of users in the package to be.",
      packageDetailsTitle3: "The value of an additional user = Number of users / Total package value.",
      packageDetailsTitle4: "The new branch = 150 SAR.",

    },
    Dashboard: {
      hello: 'Hello,',
      lawsuits: 'Cases',
      totalLawsuit: 'Total Cases',
      lawsuitCompleted: 'Case Completed',
      contract: 'Contracts',
      totalContract: 'Total Contracts',
      contractCompleted: 'Contract Completed',
      sittings: 'Court Sessions',
      totalSittings: 'Total Court Sessions',
      sittingCompleted: 'Court Session Completed',
      lawsuit: 'Case',
      branch: 'Branch',
      client: 'Client',
      dashboardSubTitle:
        'The dashboard allows you to view the latest updates regarding your available legal work.',
      duty: 'Tasks',
      totalTasks: 'Total Tasks',
      tasksCompleted: 'Task Completed',
      startDate: 'Start Date',
      endDate: 'End Date',
      agency: 'Power Of Attorney',
      employee: 'Employees',
      details: 'Details',
      totalBranches: 'Total Branches',
      totalAgency: 'Total Power Of Attorney',
      totalEmployee: 'Total Employee',
      branches: 'Branchs',
      oneAgency: 'Power Of Attorney',
      oneEmployee: 'Employee',
      clients: 'Clients',
      totalclient: 'Total Clients',
      NoData: 'No Data',
      Lawsuit: 'Lawsuit',
    },
    shared: {
      Greg: 'Gregorian',
      Hijri: 'Hijri',
      place: 'dd/month/yyyy',
      noOfEmployee: 'Number of remaining employees:',
      noOfBranches: 'Number of remaining branches:',
    },
    landingPage: {
      q1: 'What is the "Qyd" system?',
      answer1:
        '"Qyd" is an integrated system for managing legal cases and operations. It aims to assist lawyers and legal consultants in organizing and managing cases and documents efficiently and securely.',
      q2: 'How can I register for the "Qyd" system?',
      answer2:
        'To register for the "Qyd" system, you can visit our website and follow the registration steps outlined there. You will need to provide some basic information to create your account.',
      q3: 'What are the main features of the "Qyd" system?',
      answer3: 'The main features of the "Qyd" system include:',
      answer31: 'Case and document management',
      answer32: 'Appointment and task tracking',
      answer33: 'Team communication and collaboration',
      answer34: 'Reporting and analysis',
      answer35: 'Data protection and information security',
      answer36: 'Integration with other systems',
      q4: 'How are my data stored and secured in the "Qyd" system?',
      answer4:
        'We use advanced encryption technologies to protect your data, and we provide regular backups to ensure information security. Only authorized employees can access the data, ensuring the confidentiality and security of your information.',
      q5: 'Can I access the "Qyd" system from anywhere?',
      answer5:
        'Yes, the "Qyd" system is cloud-based, allowing you to access your data and manage your cases from anywhere and at any time over the internet.',
      q6: 'How can I retrieve my password?',
      answer6:
        'If you forget your password, you can click on the "Forgot Password" link on the login page and follow the instructions to retrieve your password via your registered email.',
      q7: 'Can I try the system before subscribing?',
      answer7:
        "Yes, we offer a free trial version of the 'Qyd' system. You can register for the trial version and explore the system's features before deciding to subscribe.",
      q8: 'What happens to my data if I decide to cancel the subscription?',
      answer8:
        'If you cancel your subscription, you can request a copy of all your data stored in the system. We ensure that the data is provided to you in a secure and easily retrievable manner.',
      q9: 'How can I contact the technical support team?',
      answer9:
        'You can contact the technical support team via email (support@) or by phone. We are available to assist you and answer all your inquiries.',
      q10: 'Can the "Qyd" system integrate with other systems?',
      answer10:
        'Yes, the "Qyd" system supports integration with many other financial and administrative systems, making it easy to synchronize data and unify different operations in your office.',
      des: "If you have any further questions or need additional assistance, don't hesitate to reach out to us. We are here to serve you and ensure a smooth and enjoyable experience with the 'Qyd' system.",
    },
    services: {
      mainTitle: 'Discover our services',
      service1Title: "Simplifying complex legal processes",
      service1Text: "Automation and analysis tools reduce the time spent on case management, contract review, and dispute management, with accurate reports to improve performance.",

      service2Title: "Enhancing collaboration among employees",
      service2Text: "Sharing documents and updating information in real-time on a unified platform for all employees, which enhances teamwork and reduces errors",

      service3Title: "Accurate tracking for governance management",
      service3Text: "Our tools help monitor compliance, policies, and procedures to ensure the application of the highest legal governance standards",

      service4Title: "Full control over deadlines and documents",
      service4Text: "Manage meeting and session schedules with reminder notifications, and easily access documents from any device, anytime",

      service5Title: "Integration with other systems",
      service5Text: "The system seamlessly integrates with enterprise resource planning (ERP) systems, facilitating data flow between different departments without complexity.",

      service6Title: "Maintaining privacy and security",
      service6Text: "Advanced encryption techniques ensure the protection of legal documents, with full control over who can access them to maintain confidentiality for businesses",

      service7Title: "Time savings through process automation",
      service7Text: "Automating routine tasks such as reminders, session follow-ups, and report generation allows you to focus more on your legal outputs",

      service8Title: "Simplifying case management",
      service8Text: "Our tools facilitate the organization of all cases and documents in one place with a simple interface, saving time and effort in search processes",

      btn: 'Subscribe now',
    },
    about: {
      princeHead: "What we can do is to promote the rule of law.",
      crownPrince: "Crown Prince",
      princeName: "His Royal Highness Prince Mohammed bin Salman Al Saud",
      learnMore: "More about us",
      qydPlatform: "QYD Platform",
      platformPoints: {
        point1: "Believing that society thrives through upholding justice and seeking fairness, we have launched 'QYD' to support legal professionals with smart digital solutions that organize and enhance their work. It operates on an innovative business model to streamline operations and improve the efficiency of the services provided.",
        point2: "QYD restricts all your tasks and manages them from one place at any time, by organizing contracts, handling cases, sessions, and tasks, and storing documents, with a dedicated portal for managing client relationships and developing financial resources.",
        point3: "We designed the platform to be capable of storing documents in large spaces with high privacy on servers located within Saudi Arabia, saving time, reducing costs, and enhancing business efficiency to accelerate its growth and increase customer confidence.",
      },
      message: "The message",
      messageText: "Managing legal affairs with smart digital solutions from one place.",
      vision: "The vision",
      visionText: "Comprehensive digital transformation in managing the legal system for the private sector in the Kingdom.",
      qydGoals: "QYD Goals",
      goalsHead: "We innovate digital solutions to enhance performance and secure communication.",
      goalsPoints: {
        point1Title: "Enhancing communication",
        point1Text: "Enhancing communication among legal entities to achieve goals and protect rights.",

        point2Title: "Privacy and confidentiality",
        point2Text: "Maintaining privacy and confidentiality in the storage of documents and records.",

        point3Title: "Digital solutions",
        point3Text: "Providing innovative digital solutions to address the challenges of developing legal work.",

        point4Title: "Performance of tasks",
        point4Text: "Facilitating the performance of tasks and enhancing their efficiency.",
      },

    },
    projectManagement: {
      title: "Project Management",
      search: "Search",
      projectType: "Project Type",
      clientName: "Client Name",
      projectStatus: "Project Status",
      selectPlaceholder: "Select from here...",
      searchButton: "Search",
      projectList: "Project List",
      totalProjects: "Total Projects:",
      addProject: "Add Project",
      dataNotFound: "Data Not Found",
      columns: {
        projectName: "Project Name",
        projectType: "Project Type",
        units: "Number of Units",
        unitPrice: "Unit Price",
        actions: "Actions",
        associatedWithCase: 'Associated with a Case',
        associatedWithContract: 'Associated with a Contract',
      },



      startDate: "Start Date",
      endDate: "End Date",


      addNewProject: 'Add New Project',
      mainPage: 'Main Page',
      projectManagement: 'Project Management',
      addProjectButton: 'Add Project',
      projectDetails: 'Project Details',
      underProgress: 'Under Progress',
      assignProjectManager: 'Assign Project Manager',
      selectEmployee: 'Select Employee',
      projectName: 'Project Name',
      service: 'Service',
      consultation: 'Consultation',
      associatedWithBranch: 'Is the project associated with a Case?',
      yes: 'Yes',
      no: 'No',
      contractManaged: 'Is the project managed by Contract?',
      unitPrice: 'Unit Price',
      unitCount: 'Unit Count',
      saudiRiyal: 'Saudi Riyal',
      delete: 'Delete',
      reviewCase: 'Review Case',
      next: 'Next',
      saveLater: 'Save Later',
      back: 'Back',
      convertToOpportunity: 'Convert to Opportunity',
      reviewDetails: 'Review Details',
      priceOffer: 'Price Offer',
      consultationType: 'Type of Consultation',
      consultationName: 'Consultation Name',
      serviceDescription: 'Service Description',
      writeServiceDescription: 'Write the service description here...',
      searchByCaseName: 'Search by Case Name',
      chooseCaseAssociatedWithProject: 'Choose a Case Associated with the Project',
      unregisteredCase: 'Unregistered Case?',
      addNewCase: 'Add a New Case',
      LaborConsultation: ' Labor Consultation',
      PersonalStatusConsultation: 'Personal Status Consultation ',
      RealEstateConsultation: 'Real Estate Consultation ',
      BusinessConsulting: 'Business Consulting ',
      GeneralConsultation: 'General Consultation ',
      Other: 'Other',
      ExecutionRequests: "Execution Requests",
      PerformanceOrders: "Performance Orders",
      AmicableSettlement: "Amicable Settlement",
      Reports: "Reports",
      Arbitration: "Arbitration",
      Collection: "Collection",
      CreateContract: "Create Contract",
      DraftingContract: "Drafting Contract",
      selectAssociatedContract: 'Select the contract associated with the project',
      searchContractByName: 'Search by contract name',
      addNewContract: 'Unregistered contract?',
      addNewContract2: ' Add a new contract',
      selectClient: 'Select Client',
      searchByName: 'Search by client name',
      addNewClient: 'Unregistered client?',
      addNewClient2: ' Add a new client',
      addTasksToProject: 'Add Tasks to the Project',
      addTask: 'Add Task',
      taskName: 'Task Name',
      potentialClient: 'Potential Client',
      responsible: 'Responsible',
      taskStartDate: 'Task Start Date',
      taskEndDate: 'Task End Date',
      taskStatus: 'Task Status',
      actions: 'Actions',
      attachments: 'Attachments and Categories',
      selectCategory: 'Select Category',
      category1: 'Category 1',
      category2: 'Category 2',
      attachmentName: 'Attachment Name',
      file: 'File',
      taskList: 'Task List',
      categories: 'Categories',
      phoneNumber: 'Phone Number (if available)',
      enterPhoneNumber: 'Enter phone number',
      offerStartDate: 'Offer Start Date',
      offerEndDate: 'Offer End Date',
      customerName: 'Customer Name',
      enterCustomerName: 'Enter customer name',
      customerTaxNumber: 'Customer Tax Number',
      enterCustomerTaxNumber: 'Enter customer tax number',
      nationalAddress: 'National Address',
      city: 'City',
      enterCity: 'Enter city',
      neighborhood: 'Neighborhood',
      enterNeighborhood: 'Enter neighborhood',
      postalCode: 'Postal Code',
      enterPostalCode: 'Enter postal code',
      financialDetails: 'Financial Details',
      enterUnitPrice: 'Enter unit price',
      taxRate: 'Tax Rate',
      enterTaxRate: 'Enter tax rate',
      totalWithTax: 'Total with Tax',
      enterTotalWithTax: 'Enter total with tax',
      uploadLogo: 'Upload Company Logo',
      uploadLogoBtn: 'Upload Logo',

      projectSerialNumber: 'Project Serial Number',

      projectManager: 'Project Manager',
      tasksCount: 'Tasks Count',
      tasks: 'Tasks',
      financialDepartment: 'Financial Department',
      legalDepartment: 'Legal Department',
      contractsDepartment: 'Contracts Department',
      New: 'New',
      InProgress: 'In Progress',
      AcceptOffer: 'Accept Offer',
      RemainingPayments: 'Remaining Payments',
      CompletedPayments: 'Completed Payments',
      backToProjectDetails: 'Back to Project Details',
      selectTask: "Select Task",
      taskNotRegsiter: "Task Not Registerd ?",
      ShouldInsertOneEmployeeAtLeastAndChangeToChance: 'Should Insert One Employee At Least And Change To Chance',
      converted: "Converted to opportunity",
      header: "Price Offer",
      taxNumber: "Your Tax Number (if any)",
      taxNumberPlaceholder: "Enter your tax number",
      gregorian: "Gregorian",
      hijri: "Hijri",
      clientTaxNumber: "Client Tax Number",
      clientTaxNumberPlaceholder: "Enter client tax number",
      clientNationalAddress: "Client National Address",
      district: "District",
      street: "Street",
      buildingNumber: "Building Number",
      subNumber: "Sub Number",
      financialHeader: "Financial Details",
      priceUnit: "Price Measurement Unit",
      units: "Units",
      taxAmount: "Tax Amount",
      totalAmountWithTax: "Total Amount (With Tax)",
      vatAmount: "VAT Amount",
      addresHeader: "National Address for the Entity",
      logoUpload: "Upload Entity Logo and Stamp",
      changeLogo: "Change Logo",
      uploadStamp: "Upload Stamp Image",
      entityStamp: "Entity Stamp",
      note: "Note: Please upload the logo and signature with a white background in PNG format.",
      Units: "Units",
      Lawsuits: "Lawsuits",
      FileRequired: 'File Required',
      Visits: "Visits",
      phoneNumber2: 'Phone Number',
      select: "Select...",
      add_invoice: "Add Invoice",
      view_details: "View Details",
      edit_data: "Edit Data",
      download_quote: "Download Quote",
      financial_section: "Financial Section",
      main_page: "Main Page",
      projects_management: "Projects Management",
      price_quote: "Price Quote",
      payment_notice: "Payment Notice",
      price_quote_data: "Price Quote Data",
      offer_end_date: "Offer End Date",
      offer_date: "Offer Date",
      project_serial_number: "Project Serial Number",
      client_name: "Client Name",
      total_with_tax: "Total (with Tax)",
      is_offer_approved: "Is Offer Approved",
      bills: "Bills",
      includes_invoice: 'Includes Invoice?',
      is_paid: 'Is Paid?',
      issue_date: 'Issue Date',
      serial_number: 'Serial Number',
      view_notice: 'View Notice',
      add_payment_invoice: 'Add Payment Invoice',
      payment_number: "Payment Number",
      invoice_number: "Invoice Number",
      invoice_issue_date: "Invoice Issue Date",
      view_invoice: "View Invoice",
      download_invoice: "Download Invoice",
      UpdatedSuccessFully: "Updated SuccessFully",
      AddedSdadaSuccessFully: "Added Sdada SuccessFully",
      add_payment_notice: "Add Payment Notice",
      establishment_banking_info: "Banking Information for the Establishment",
      create_payment_notice: "Create Payment Notice",
      project_name: "Project Name",
      tax_number: "Tax Number",
      offer_serial: "Offer Serial",
      date_picker: "Select Start Date",
      amount: "Amount",
      description: "Description",
      bank_name: "Bank Name",
      iban_number: "IBAN Number",
      account_holder_name: "Account Holder Name",
      select_bank_name: "Select Bank Name",
      example_description: "Example: For the second installment under the legal services contract including VAT",
      submit: "Submit",
      cancel: "Cancel",
      task_list: "Task List",
      task_name: "Task Name",
      assigned_to: "Assigned To",
      task_status: "Task Status",
      start_date: "Start Date",
      end_date: "End Date",
      activation_status: "Activation Status",
      details: "Details",
      edit_task: "Edit Task",
      task_details: "Task Details",
      ongoing: "Ongoing",
      completed: "Completed",
      contract_list: "Contract List",
      total_contracts: "Total Contracts",
      contract_title: "Contract Title",
      contract_status: "Contract Status",
      branch_name: "Branch Name",
      creation_date: "Creation Date",
      project_associated: "Project Associated",
      view: "View",
      assigned_members: "Assigned Members",
      active: "Active",
      inactive: "Inactive",
      enabled: "Enabled",
      disabled: "Disabled",
      case_management: "Case Management",
      total_cases: "Total Cases",
      case_number: "Case Number",
      case_number_in_court: "Case Number in Court",
      case_type: "Case Type",
      case_status: "Case Status",
      court: "Court",
      accounting_summary: "Accounting Summary",
      total_amount: "Total",
      export_data: "Export Data",
      pdf: "PDF",
      excel: "Excel",
      account_statement: "Account Statement",
      reference_number: "Reference Number",
      transaction_date: "Transaction Date",
      transaction_type: "Transaction Type",
      project: "Project Name",
      debit: "Debit",
      credit: "Credit",
      payments_table: "Payments Table",
      trial_balance: "Trial Balance",
      start_date2: "Select Start Date",
      end_date2: "Select End Date",
      payment_table: "Payment Table",
      payment_date: "Payment Date",
      paid_invoice: "Paid Invoice",

      opening_balance: "Opening Balance",
      movement: "Movement",
      closing_balance: "Closing Balance",
      balance: "Balance",
      project_added_successfully: "Project Added Successfully",
      review_and_download: "You can review the quote and download it here or navigate to the Projects page.",
      quote_preview: "Quote Preview",
      navigate_to_projects: "Navigate to Projects",
      vat_number: "VAT Number",
      issue_date2: "Issue Date",
      expiry_date: "Expiry Date",
      client_address: "Client Address",
      item_number: "Item",
      unit_price: "Unit Price",
      taxable_amount: "Taxable Amount",
      tax_rate: "Tax Rate",
      tax_amount: "Tax Amount",
      total_with_tax2: "Total with Tax",
      subtotal: "Subtotal (excl. tax)",
      tax_total: "Total Tax",
      total_amount2: "Grand Total",
      client_vat: "Vlient Vat",
      view_payment_notice: "View Payment Notice",


      to: "To",
      service_name: "Service Name",
      account_name: "Account Name",
      bank_transfer: "Bank Transfer",
      iban: "IBAN",
      note_text:
        "Please ensure that the beneficiary name matches exactly as specified to avoid payment rejection, per the guidelines of the Saudi Central Bank. Refer to the payment notice above.",
      download: "Download",

      simplified_tax_invoice: "Simplified Tax Invoice",
      TaxInvoice: "Tax Invoice",
      invoice_due_date: "Invoice Due Date",
      payment_reference: "Payment Reference",
      product_name: "Product Name",
      unified_id: "Unified ID for Non-Governmental Entity",
      client_tax_number: "Client Tax Number",
      // taxable_amount: "Taxable Amount",
      // tax_rate: "Tax Rate",
      // tax_amount: "Tax Amount",
      // total_with_tax: "Total with Tax",
      // subtotal: "Subtotal (excl. tax)",
      // tax_total: "Total Tax Amount",
      // total_amount: "Grand Total",
      download_invoice2: "Download",
      Invoice: "Invoice",
      PaymentNotice: "Payment Notice",
      invoice_number2: "Payment Notice Number"

    }
    ,
    auth: {
      newAccount: "New Account",
      phone: 'Phone Number',
      email: "Email",
      addAccount: "Register Now",
      otp: {
        enterCode: 'Enter Verification Code',
        sentMessage: 'A verification code has been sent to your phone number',
        sentMessage2: 'A verification code has been sent to your email',
        resendMessage: 'Resend code in',
        resendCode: 'Resend Code',
        confirmAccount: 'Confirm Account',
        editPhoneNumber: 'Edit Phone Number',
        editEmail: "Edit Email"
      },
      selectPackage: "Choose the package that suits you",
      packagePrice: "The package price changes according to the number of users",
      userCount: "Number Of Users",
      startTrial: 'Start Free Trial',

    },
    customerManagement: {
      EmailOrPhoneIsExists: 'The email or phone number is already registered.',
      userManagement: 'User Management',
      employeeList: 'User List',
      management: 'Management',
      actions: 'Actions',
      employee: 'Users',
      employee1: 'User',
      search: 'Search by name or email',
      phonesearch: 'Search by phone number',
      completed: 'Completed',
      inCompleted: 'Not Completed',
      active: 'Active',
      inActive: 'Inactive',
      All: 'All',
      allCustomers: 'Subscriber Management',
      activeMembers: 'Active Members',
      playerName: 'User Name',
      playerStatus: 'Status',
      mobileNumber: 'Phone Number',
      Email: 'Email',
      country: 'Country',
      accountStatus: 'Account Status',
      emailStatus: 'Email Activation Status',
      Block: 'Block',
      blockStatus: 'Block Status',
      total: 'Total',
      Blocked: 'Blocked',
      notBlocked: 'Not Blocked',
      NotFound: 'No data available',
      addNew: 'New User',
      Details: 'Account Details',
      edit: 'Edit Data',
      delete: 'Delete',
      branch: 'Branch',
      Confirmed: 'Confirmed',
      NotConfirmed: 'Not Confirmed',
      ok: 'OK',
      cancel: 'Cancel',
      confirmModalTitle: 'Confirmation',
      activeMessage: 'Do you want to change the account status?',
      changeAccountStatus: 'Account status changed successfully.',
      branchName: 'Branch Name',
      more: 'Show More',
      allbranch: 'All Branches',
      editEmployee: 'Edit User Data',
      addEmployee: 'Add New User',
      name: 'User Name',
      writeName: 'Enter User Name',
      SelectOption: 'Select Option',
      email: 'Enter User Email',
      phoneNumber: 'Phone Number',
      save: 'Add User',
      confirmDel: 'You are about to delete the user. Are you sure?',
      addSuccess: 'User added successfully.',
      editSuccess: 'User data updated successfully.',
      licenceNo: 'Enter License Number',
      licenceDate: 'License Issue Date',
      licenceEndDate: 'License Expiry Date',
      confirmDelte: 'Changing the branch will delete all assigned tasks for this user.',
      deleteuccess: 'Branch deleted successfully.',
      confirmDelteBranch: 'Confirm deletion of the user from the branch.',
      employeeType: 'User Type',
      Individual: 'Individual',
      teamName: 'Team Name',
      details: 'User Details',
    },
    deactivate: {
      CONFIRM_DEACTIVATION: 'Confirm Account Deactivation',
      DEACTIVATION_WARNING: 'Your account will now be deactivated and you will be logged out.',
      DEACTIVATE_BUTTON: 'Deactivate Account',
      CANCEL_BUTTON: 'Cancel',
      DEACTIVATE_ACCOUNT: 'Deactivate Account',
    },
    errorPage: {
      ERROR_TITLE: 'Something went wrong!!',
      ERROR_DESCRIPTION: 'It seems you followed a broken link or the page has a technical issue. We are working on fixing it.',
      ERROR_BUTTON: 'Go to Homepage',
      SERVER_ERROR_TITLE: '500 Internal Server Error',
      SERVER_ERROR_MESSAGE: 'An internal server error occurred. Please try again later.',
      SERVER_ERROR_BUTTON: 'Go to Homepage'
    }
  }
};
