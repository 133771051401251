// Arabic
export const locale = {
  lang: 'ar',
  ar: 'العربية',
  en: 'English',
  currentTheme: 'rtl',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    breadcrumbs: {
      customerManagement: "إدارة المستخدمين",
      addCustomerManagement: "إضافة مستخدم جديد",
      home: 'الصفحة الرئيسية',
      admin: 'مدير النظام',
      agent: 'منشأة',
      contact: 'تواصل معنا',
      privacy: 'سياسة الخصوصية',
      question: 'الاسئلة الشائعة',
      projects: "إدارة المشاريع",
      usermanagement: 'إدارة المشتركين',
      employeemanagement: 'إدارة الموظفين',
      branchmanagement: 'إدارة الفروع',
      editprofile: 'تحديث الملف الشخصي',
      contractmanagement: 'إدارة العقود',
      viewcontract: 'استعراض بيانات العقد',
      viewuser: 'استعراض بيانات المشترك',
      addcase: 'إضافة قضية',
      editcase: 'تعديل قضية',
      viewcase: 'استعراض القضية',
      logcase: 'سجل القضايا',
      addcontract: 'إضافة عقد',
      editcontract: 'تعديل عقد',
      casemanagement: 'إدارة القضايا',
      editclient: 'تعديل العميل',
      addclient: 'إضافة العميل',
      clientmanagement: 'إدارة العملاء',
      workdutymanagement: 'إدارة المهام',
      addworkduty: 'إضافة مهمة',
      editworkduty: 'تعديل مهمة',
      sittingmanagement: 'إدارة الجلسات',
      addsitting: 'إضافة جلسة',
      editsitting: 'تعديل جلسة',
      viewSittingHistory: 'سجل الجلسات',
      viewSitting: 'استعراض الجلسة',
      viewworkduty: 'استعراض المهام',
      viewclient: 'استعراض عميل',
      Edit: 'تعديل',
      Add: 'إضافة',
      contract: 'إدارة العقود',
      assignedTeam: 'المكلفين',
      notification: 'الإشعارات',
      addbranch: 'إضافة فرع',
      editbranch: 'تعديل فرع',
      viewbranch: 'استعراض الفرع',
      addemployee: 'إضافة موظف',
      editemployee: 'تعديل موظف',
      viewemployee: 'استعراض موظف',
      attornymanagement: 'إدارة الوكالات',
      editattorny: 'تعديل الوكالة',
      addattorny: 'إضافة الوكالة',
      viewattorny: 'استعراض بيانات الوكالة',
      payment: 'المدفوعات',
      report: 'محضر',
      upgradePackage: 'إدارة الاشتراكات',
      invoiceDetails: 'تفاصيل الفاتورة',
      addPackage: 'إضافة باقة',
      editPackage: 'تعديل باقة',
      packagesmanagement: 'إدارة الباقات',
      Confirm: 'تأكيد الدفع',
      services: "الخدمات",
      about: "عن قيد",
      filled: "النماذج المعبأة",
      addProject: "إضافة مشروع",
      financialDepartment: 'قسم المالية',
      accountingSummary: "الملخص المحاسبي"
    },
    menu: {
      setting: 'الإعدادات',
      playerManagement: 'إدارة المشتركين',
      editProfile: 'تحديث الملف الشخصي',
      package: 'إدارة الباقات',
      message: 'إدارة الرسائل و الشكاوي',
      statistics: 'التقارير',
      notifications: 'الإشعارات',
      department: 'الإدارات',
      employeeManagement: 'إدارة الموظفين',
      home: 'الرئيسية',
      about: 'عن قيد',
      packageM: 'الباقات',
      contact: 'تواصل معنا',
      dashboard: 'لوحة المعلومات',
      login: 'تسجيل الدخول',
      attornyManagement: 'إدارة الوكالات',
      payment: 'المدفوعات',
      filled: 'النماذج المعبأة',
      upgradePackage: 'إدارة الاشتراكات',
      services: 'الخدمات',
      soon: 'هذه النسخة تم توفيرها لأغراض تجريبية فقط وليست معدة للبيع حالياً',
    },

    LogIn: {
      SignOut: 'تسجيل خروج',
      CodeYouEnteredIncorrect: 'الكود الذي ادخلته غير صحيح',
      CodeResendSuccess: 'تم ارسال الرمز التاكيدي مرة اخري من فضلك تاكد من ذلك',

      infoTitle: 'معلومات تسجيل ضريبة القيمة المضافة في نموذج البيانات',
      qMessage: 'هل أنت مسجل في ضريبة القيمة المضافة؟',
      qydSuperAdmin: 'مدير النظام',
      yes: 'نعم',
      no: 'لا',
      qydAgent: 'منشأة',
      qydEmployee: 'موظف',
      MessageValidation:
        'يجب استكمال البيانات الخاصة بالتسجيل للتتمكن من استخدام المنصة',
      clickIn: 'اضغط هنا ',
      attention: 'تنبية',
      back: 'رجوع',
    },
    users: {
      QYDAgent: 'منشأة',
      QYDSuperAdmin: 'مدير النظام',
      admin: 'admin',
      QYDEmployee: 'موظف',
      QYDManager: 'مدير فرع',
    },
    loginPage: {
      logIn: 'تسجيل الدخول',
      logInEmail: 'البريد الإلكتروني',
      thisFieldIsRequired: 'هذا الحقل مطلوب',
      thisFieldIsShouldHaveSpecialCharacter: 'كلمة المرور يجب أن تحتوي على أحرف كبيرة (مثل A-Z)',
      CAPTCHA_TITLE: 'الرمز المرئي',
      Password: 'كلمة المرور',
      rememberme: 'تذكرني',
      enterEmail: 'أدخل البريد الإلكتروني',
      enterPassword: 'أدخل كلمة المرور',
      ForgotPassword: 'نسيت كلمة المرور؟',
      noAccount: 'ليس لديك حساب؟',
      hasAccount: 'لديك حساب؟',
      createAccount: 'إنشاء حساب جديد',
      minLength: ' لا يمكن أن يكون العدد أقل من 1 ',
    },
    userManagement: {
      playerManagement: 'إدارة المشتركين',
      userList: 'قائمة المشتركين',
      search: ' البحث بالاسم أو البريد الإلكتروني',
      searchByName: ' البحث بالاسم',
      completed: 'مكتمل',
      inCompleted: 'غير مكتمل',
      active: 'مفعل',
      inActive: 'غير مفعل',
      All: 'الكل',
      management: 'إدارة',
      user: 'المشتركين',
      allCustomers: 'إدارة المشتركين',
      activeMembers: 'الأعضاء النشيطين',
      playerName: 'اسم المشترك',
      playerStatus: ' الحالة  ',
      mobileNumber: 'رقم الجوال',
      Email: 'البريد الإلكتروني',
      country: 'الدولة',
      accountStatus: 'حالة الحساب',
      emailStatus: 'حالة تفعيل البريد',
      Block: 'حظر',
      emailsend: 'إرسال بريد تفعيل',
      blockStatus: 'حالة الحظر',
      total: 'المجموع',
      Blocked: 'محظور',
      notBlocked: 'غير محظور',
      NotFound: 'لا توجد بيانات متاحة',
      Confirmed: 'مفعل',
      NotConfirmed: 'غير مفعل',
      Details: 'تفاصيل',
      ok: 'التأكيد',
      cancel: 'إلغاء',
      confirmModalTitle: 'تأكيد',
      activeMessage: 'هل تريد تغيير حالة الحساب؟',
      changeAccountStatus: 'تم تغيير حالة الحساب بنجاح',
      sendEmail: 'تم إرسال بريد التفعيل بنجاح',
    },
    filledForms: {
      formFilled: "الناذج المعبأة",
      requestStatus: "حالة الطلب",
      formsList: "قائمة الماذج المعبأة",
      clientName: "اسم العميل",
      details: "التفاصيل"
    },
    register: {
      completeInfo: "اكمل البيانات",
      createAccount: 'إنشاء حساب جديد',
      back: 'رجوع',
      reasonType: 'نوع الاهتمام ',
      institutionName: 'اسم المنشأة ',
      licenseNumber: 'رقم السجل التجاري',
      agree: 'أوافق على',
      terms: ' اتفاقية مستوى الخدمة',
      signUp: 'تسجيل حساب',
      hasAccount: 'لدي حساب بالفعل',
      signIn: 'تسجيل دخول',
      entername: 'قيود للمحاماة',
      enternumber: 'أدخل رقم السجل التجاري',
      userName: 'اسم المستخدم الكامل',
      enterUserName: 'أدخل اسم المستخدم الكامل',
      email: 'البريد الإلكتروني',
      addEmailSuccess: ' تم استلام بيانات طلب التسجيل بنجاح',
      enterEmail: 'أدخل البريد الإلكتروني',
      phoneNumber: 'رقم الجوال',
      enterphoneNumber: '5xxxxxxx',
      accountType: 'نوع العمل',
      SelectOption: 'اختر المدينة',
      accountTypeRequired: 'اختر نوع العمل ',
      next: 'التالي',
      LawyerOffice: 'مكتب محاماة',
      LegalDepartment: "الادارة القانونية",
      RegularRepresentative: 'ممثل نظامي',
      lawyer: ' محامي',
      Traininglawyer: ' محامي متدرب',
      Licensedlawyer: '  محامي مرخص',
      LegalAdministration: 'باحث قانوني ',
      Advisor: 'مستشار قانوني',
      register: 'تسجيل الحساب',
      addSuccess: 'تم تسجيل الحساب بنجاح',
      accountInfo: 'بيانات الحساب',
      logo: 'إرفاق شعار المنشأة',
      sector: 'قطاع المنشأة',
      organizationSectorsRequired: 'أدخل قطاعات المنشاة',
      employeeNo: 'عدد الموظفين',
      employeeNoRequired: 'أدخل عدد الموظفين',
      organizationEmail: 'البريد الإلكتروني الخاص بالمنشأة',
      country: 'الدولة',
      saudi: 'المملكة العربية السعودية',
      countryIdRequired: 'اختر الدولة',
      region: 'المنطقة',
      regionIdRequired: 'اختر المنطقة',
      city: 'المدينة',
      cityIdRequired: 'اختر المدينة',
      currency: 'العملة',
      currencyIdRequired: 'اختر العملة',
      agentAddress: 'عنوان المنشأة ',
      agentAddressIsRequired: 'أدخل عنوان المنشأة بالتفصيل',
      NotificationLanguage: 'لغة استقبال الإشعارات',
      NotificationLanguageIsRequired: 'اختر لغة استقبال الإشعارات',
      arabic: 'اللغة العربية',
      english: 'اللغة الإنجليزية',
      save: 'حفظ',
      logoRequired: 'من فضك أرفق صورة',
      VATRegistrationNumber: 'رقم التسجيل في ضريبة القيمة المضافة',
      CommercialRegistrationCertificate: 'شهادة السجل التجاري',
      VATCertificate: 'شهادة ضريبة القيمة المضافة',
    },
    forgetPassword: {
      back: 'عودة للصفحة الرئيسية',
      password: 'كلمة المرور',
      ForgetYourPassword: 'إعادة تعيين كلمة المرور',
      EnterYourEmail: 'أدخل بريدك الإلكتروني الذي قمت بالتسجيل به',
      APasswordResetLinkWillBeSent: 'ليتم إرسال رابط إعادة تعيين كلمة المرور',
      email: 'البريد الإلكتروني',
      resetSuccess: 'تم تعيين كلمة المرور بنجاح',
      SendLink: 'إرسال',
      PasswordResetLinkWillBeSent: 'تم إرسال رابط إعادة تعيين كلمة المرور إلى',
      EmailSentSuccessfully: 'تم إرسال رابط إعادة تعيين كلمة المرور بنجاح',
      MailTo: '  إلى',
      DoNotReceiveEmail: 'لم يتم إرسال الإيميل',
      ResendEmail: 'إعادة إرسال',
      ResetYourPassword: 'تعيين كلمة المرور',
      EnterPassword: 'أدخل كلمة المرور الخاصة بك',
      NewPassword: 'كلمة المرور الجديدة',
      ConfirmPassword: 'تأكيد كلمة المرور',
      oldPassword: 'كلمة المرور القديمة',
      passwordRole:
        'يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير وحرف خاص ورقم واحد على الأقل',
    },

    AgentRegisteration: {
      YourAccountRegisteredSuccessfully: 'تم التسجيل بنجاح',
      ActivateEmailMessage: 'لتفعيل الحساب الرجاء الدخول إلى البريد الإلكتروني',
      ActivateEmailMessage2: 'وتفعيل الرابط المرفق',
      back: 'متابعة',
    },
    Shared: {
      TheMaximumFileAllowed: 'يجب أن يكون حجم بحد أقصى 2MB',
      CheckUploadedFileFormat: ' تاكد من صيغة الملف المرفوع',
    },
    confirmEmail: {
      accoutActivate: 'تم تأكيد البريد الخاص بك',
      accoutActivate2: 'من فضلك سجل دخول لإكمال الملف الشخصي',
      mainPage: 'عودة للصفحة الرئيسية',
      error: 'خطأ',
      accoutActivateEmployee:
        'من فضلك قم بتغيير كلمة المرور لتتمكن من الدخول إلى حسابك',
      resetPassword: 'تغيير كلمة المرور',
    },
    Validation: {
      EmailIsInValid: 'البريد الإلكتروني غير صحيح',
      emailRequired: ' من فضلك أدخل بريدك الإلكتروني',
      organizationEmailRequired: 'أدخل البريد الإلكتروني للمنشاة',
      passwordRequired: 'من فضلك أدخل كلمة المرور',
      PasswordIsRequired: 'كلمة المرور مطلوبة',
      invalidPasswordPattern: "يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل، حرف كبير، حرف صغير، رقم، ورمز خاص.",
      PasswordPattern: '',
      UsernameIsInValid: 'من فضلك أدخل اسم المستخدم',
      PasswordMatch: 'كلمة المرور غير متطابقة',
      EmailMatch: ' البريد الإلكتروني غير متطابق',
      codeRequired: 'من فضلك أدخل كود التفعيل',
      thisFieldIsRequired: 'هذا الحقل مطلوب',
      englishOnly: 'هذا الحقل مسموح باللغة الانجليزية فقط',
      arabicOnly: 'هذا الحقل مسموح باللغة العربية فقط',
      numberonly: 'هذا الحقل مسموح بالأرقام فقط',
      MobileNumberIsRequired: 'من فضلك أدخل رقم الجوال',
      agreeIsRequired: 'يجب الموافقة على سياسة مستوي الخدمة',
      maxLength: 'يجب أن يكون الرمز مكون من أربعة أرقام',
      MobileNumberLength:
        ' يجب أن لا يزيد رقم الجوال عن ١٦ رقم و لا يقل عن 9 أرقام',
      thisFieldShouldHaveMinimumLength: "يجب أن يحتوي رقم الهاتف على 9 أرقام على الأقل.",
      thisFieldShouldHaveMaximumLength: "يجب ألا يتجاوز رقم الهاتف 16 رقمًا.",
      invalidPhoneNumber: "من فضلك أدخل رقم جوال صالح",
      recaptchaRequired: "الرمز المرئى مطلوب"
    },
    employeeManagement: {
      playerManagement: 'إدارةالموظفين',
      employeeList: 'قائمة الموظفين',
      management: 'إدارة',
      actions: "الإجراءات",
      employee: 'الموظفين',
      employee1: "موظف",
      employee2: "اسم الفريق المكلف",
      employee3: "اسم الموظف المكلف",
      teamMember: "اعضاء الفريق",
      editTeam: "تعديل الفريق",
      team: 'فريق',
      selectTeam: "حدد الفريق",
      search: 'البحث بالاسم أو البريد الإلكتروني',
      phonesearch: 'البحث برقم الجوال',
      completed: 'مكتمل',
      inCompleted: 'غير مكتمل',
      active: 'مفعل',
      inActive: 'غير مفعل',
      All: 'الكل',
      allCustomers: 'إدارة المشتركين',
      activeMembers: 'الأعضاء النشيطين',
      playerName: 'اسم الموظف',
      playerStatus: ' الحالة  ',
      mobileNumber: 'رقم الجوال',
      Email: 'البريد الإلكتروني',
      country: 'الدولة',
      accountStatus: 'حالة الحساب',
      emailStatus: 'حالة  تفعيل البريد ',
      Block: 'حظر',
      blockStatus: 'حالة الحظر',
      total: 'المجموع',
      Blocked: 'محظور',
      notBlocked: 'غير محظور',
      NotFound: 'لا توجد بيانات متاحة',
      addNew: 'موظف جديد',
      Details: 'تفاصيل الحساب',
      edit: 'تعديل البيانات',
      delete: 'حذف',
      branch: 'الفرع',
      Confirmed: 'مفعل',
      NotConfirmed: 'غير مفعل',
      ok: 'التأكيد',
      cancel: 'إلغاء',
      confirmModalTitle: 'تأكيد',
      activeMessage: 'هل تريد تغيير حالة الحساب؟',
      changeAccountStatus: 'تم تغيير حالة الحساب بنجاح',
      branchName: 'اسم الفرع',
      more: 'عرض المزيد',
      allbranch: 'جميع الفروع',
      createTeam: "إنشاء فريق",

    },
    createTeam: {
      createNewTeam: "انشاء فريق جديد",
      teamName: "اسم الفريق",
      writeTeamName: "اكتب أسم الفريق",
      addNewMember: "اضافة اعضاء للفريق",
      username: "اسم المستخدم",
      addBtn: "إضافة",
      newMembers: "الاعضاء الجدد",
      selectNewMember: 'تحديد المستخدمين',
      selectMembersPlaceholder: 'تحديد أعضاء للفريق',
      cancelAndReturn: 'إلغاء وعودة',
      saveTeam: 'حفظ الفريق',

    },
    emplyeeEditAdd: {
      editEmployee: 'تعديل بيانات موظف',
      UserRole: 'دور المستخدم',
      Employee: 'موظف',
      SystemAdministrator: 'مسئول نظام',
      addEmployee: 'إضافة موظف جديد',
      name: ' اسم الموظف',
      JobTitle: 'المسمي الوظيفي',
      JobTitleWrite: 'اكتب المسمي الوظيفي',
      writeName: ' اكتب اسم الموظف',
      writeTeamName: ' اكتب اسم الفريق',
      branch: 'اسم الفرع',
      SelectOption: 'اختر',
      email: ' ادخل البريد الإلكتروني للموظف',
      phoneNumber: 'رقم الجوال',
      save: 'حفظ',
      cancel: 'إلغاء',
      confirmDel: 'أنت على وشك حذف الموظف . هل أنت متأكد؟',
      activeMessage: 'تم حذف الموظف بنجاح',
      delete: 'تأكيد حذف موظف',
      addSuccess: 'تم إضافة الموظف بنجاح',

      editSuccess: 'تم تعديل بيانات الموظف بنجاح',
      licenceNo: ' ادخل رقم الترخيص',
      licenceDate: 'تاريخ اصدار الترخيص',
      licenceEndDate: 'تاريخ انتهاء الترخيص',
      confirmModalTitle: 'تأكيد حذف فرع',
      confirmDelte: 'عند تغيير الفرع سيتم حذف جميع المهام الموكلة لهذا الموظف',
      deleteuccess: ' تم حذف الفرع بنجاح',
      confirmDelteBranch: 'تأكيد حذف موظف من الفرع',
      employeeType: "نوع الموظف",
      Individual: "فرد",
      team: "فريق",
      teamName: "اسم الفريق",

    },
    emplyeeDetails: {
      details: 'بيانات الموظف ',
      cancel: 'رجوع',
    },
    branches: {
      branchesManagement: 'إدارة الفروع',
      branshList: 'قائمة الفروع',
      branches: 'الفروع',
      management: 'إدارة',
      branch: 'الفرع',
      addNew: 'فرع جديد',
      search: 'البحث باسم الفرع',
      accountStatus: 'حالة الفرع',
      active: 'مفعل',
      inActive: 'غير مفعل',
      All: 'الكل',
      NotFound: 'لا توجد بيانات متاحة',
      name: 'اسم الفرع',
      status: 'حالة الفرع',
      edit: 'تعديل البيانات',
      delete: 'حذف',
      confirmModalTitle: 'تغيير حالة الفرع',
      activeMessage: 'هل أنت متأكد من تغيير حالة الفرع ؟',
      changeAccountStatus: 'تم تغيير حالة الفرع',
      branchManager: 'مدير الفرع',
      region: 'المنطقة',
      employeeNo: 'عدد الموظفين',
      branchDetails: 'تفاصيل الفرع',
      branchInfo: 'بيانات الفرع',
      select: 'اختر',
      BranchOrOffice: 'فرع/مكتب'
    },
    branchEditAdd: {
      addBranch: 'إضافة فرع جديد',
      editBranch: 'تعديل فرع',
      arabicName: ' اسم الفرع',
      WriteArabicName: 'اكتب اسم الفرع',

      englishName: 'اسم الفرع باللغة الإنجليزية',
      save: 'حفظ',
      cancel: 'إلغاء',
      delete: 'حذف الفرع',
      confirmDel: 'أنت على وشك حذف الفرع . هل أنت متأكد؟',
      activeMessage: 'تم حذف الفرع بنجاح',
      addSuccess: 'تم إضافة الفرع بنجاح',
      editSuccess: 'تم تعديل بيانات الفرع بنجاح',
      branchInfo: 'بيانات الفرع',
      region: 'المنطقة',
      branchManager: 'مدير الفرع',
      employeeNo: 'ادخل عدد الموظفين ',
      another: 'أخرى',
      disableLabel: 'يجب إضافة موظفين للفرع',
    },
    userDetails: {
      userDetails: 'تفاصيل المشترك',
      agentName: 'اسم المنشأة',
      commercialNo: 'رقم السجل التجاري',
      employeeNo: 'عدد الموظفين',
      accountTyoe: 'نوع الحساب',
      Establishmentsector: 'قطاع المنشأة',
      currency: 'العملة',
      region: 'المنطقة',
      city: 'المدينة',
      agentAddress: 'عنوان المنشأة',
      agentEmail: 'البريد الإلكتروني الرسمي للمنشأة',
    },
    editProfile: {
      edit: 'تحديث ',
      profile: 'الملف الشخصي',
      editProfile: 'تحديث الملف الشخصي',
      personalInfo: 'البيانات الشخصية',
      agentInfo: 'بيانات المنشأة',
      addSuccess: 'تم تعديل البيانات بنجاح',
      addPasswordSuccess: 'تم تعديل كلمة المرور بنجاح',
      ConfirmEmail: 'تأكيد البريد الإلكتروني',
      email: 'البريد الإلكتروني',
      sendCode: 'تأكيد البريد الإلكتروني',
      send: 'إرسال',
      confirmEmailMessage: 'لقد تم إرسال رمز التفعيل لبريدك الإلكتروني',
      EnterCode: 'رمز التأكيد',
      sendemailSuccess: 'تم تعديل البريد الإلكتروني بنجاح',
      sendemailErrro: 'الرمز خطأ',
      editVat: 'تعديل الضريبة',
    },
    contract: {
      contractManagement: 'إدارة العقود',
      notFound: 'لاتوجد',
      subTitle: 'جميع المشتركين في موقعك هنا',
      search: 'البحث',
      contractAddress: 'عنوان العقد',
      plzChoose: 'اختر من هنا',
      contractStatus: 'حالة العقد',
      All: 'الكل',
      Active: 'نشط',
      Deferred: 'مؤجلة',
      Finished: 'منتهية',
      Canceled: 'ملغية',
      Pending: 'معلقة',
      NotFound: 'لا توجد بيانات متاحة',
      startDate: 'تاريخ بداية العقد',
      endDate: 'تاريخ نهاية العقد',
      user: 'العميل',
      branch: 'الفرع',
      caseStatus: 'حالة التفعيل',
      contractTitle: 'قائمة العقود',
      addNew: 'عقد جديد',
      total: 'الإجمالي',
      confirmModalTitle: 'تغيير حالة العقد',
      activeMessage: 'هل تريد بالتأكيد تغيير حالة العقد ؟',
      contractDetail: 'تفاصيل العقد',
      contractInfo: 'بيانات العقد',
      InActive: 'غير نشط',
      contractSubject: 'تفاصيل و بنود العقد',
      contractTeam: 'الفريق المكلف',
      remember: 'تذكير',
      timeRemain: 'أيام قبل انتهاء العقد ',
      NotAuth: 'عذرًا ليس لديك صلاحية الإطلاع على هذه المعلومات',
      changeAccountStatus: 'تم تغيير حالة العقد بنجاح ',
      ApplicationReview: 'مراجعة العقد',
      day: 'يوم',
    },
    packageError: {
      finishEmployee:
        'لقد وصلت إلى الحد الأقصى , الرجاء ترقية باقتك للتتمكن من الإضافة',

      finishBranch:
        'لقد وصلت إلى الحد الأقصى لعدد الفروع , الرجاء ترقية باقتك للتتمكن من إضافة فرع جديد',
      expired:
        'نود إعلامك بأن الباقة الخاصة بك مع قيد قد انتهت، نرجو منك تجديد الباقة الخاصة بك في أقرب وقت ممكن.',
      invalid:
        'هذه الخدمة غير متوفرة لباقتك الحالية الرجاء ترقية باقتك للحصول على الخدمة',
      expiredEmployee:
        'نود إعلامك بأن باقة المنشأة الخاصة بك مع قيد قد انتهت، نرجو منك التواصل مع المنشأة لتجديد الاشتراك',
      sorry: 'عذرا !!',
      PackageUpgrade: 'ترقية الباقة',
    },

    issue: {
      changeAccountStatus: 'تم تغيير حالة القضية بنجاح ',
      issueManagement: 'إدارة القضايا',
      issueNo: 'رقم القضية في المحكمة',
      issueStatus: 'حالة القضية',
      court: 'المحكمة',
      issueType: 'نوع القضية',
      issueTeam: 'المكلفين',
      issueList: 'قائمة القضايا',
      addNew: 'قضية جديدة',
      teamName: 'اسم المكلف',
      All: 'الكل',
      Individual: 'فرد',
      Special: 'خاص',
      Governmental: 'حكومي',
      Active: 'نشط',
      ddd: 'الجهة',
      UnderConsideration: 'قيد النظر',
      Finished: 'منتهية',
      Canceled: 'ملغية',
      Pending: 'معلقة',
      Details: 'استعراض',
      edit: 'تعديل بيانات',
      glsat: 'الجلسات',
      log: 'سجل القضية',
      issueDetail: 'تفاصيل القضية',
      issueInfo: 'بيانات القضية',
      mainCourt: 'المحاكم واللجان شبه القضائية',
      subCourt: 'المحاكم واللجان الفرعية',
      issueDate: 'تاريخ قيد القضية',
      appStatus: 'حالة الطلب',
      Inactive: 'غير نشط',
      issueSubject: 'موضوع المدعي',
      PlaintiffRequests: 'طلبات  المدعي',
      PlaintiffEvidence: 'أسانيد المدعي',
      defendantRequests: 'طلبات المدعي عليه',
      defendantEvidence: 'أسانيد المدعي عليه',
      branchName: 'اسم الفرع',
      assignedName: 'اسم المكلف',
      Applicant: 'مقدم الطلب',
      applicantType: 'نوع مقدم الطلب',
      PlaintiffName: 'اسم المدعي',
      PlaintiffType: 'نوع المدعي',
      DefendantName: 'اسم المدعى عليه',
      DefendantType: 'نوع المدعى عليه',
      idNo: 'الهوية الوطنية/الإقامة',
      nationality: 'الجنسية',
      Defendant: 'المدعى عليه',
      attachments: 'المرفقات',
      fileName: 'اسم الملف',
      uploadDate: 'تاريخ الرفع',
      uploadBy: 'رفع بواسطة',
      download: 'تحميل',
      confirmModalTitle: 'تغيير حالة القضية',
      activeMessage: 'هل تريد بالتأكيد تغيير حالة القضية ؟',
      isDraft: 'مسودة',
      details: 'التفاصيل',
      issueState: 'حالة التفعيل',
      workDuy: 'المهام',
      newSession: 'جلسة جديدة',
      newWork: 'مهمة جديدة',
      najez: 'يجب أن تكون القضية مسجله في ناجز حتى تستطيع إضافتها',
    },
    addIssue: {
      clientName: "اكتب اسم المدعى",
      clientName2: "صفة ممثل الجهة",
      writeName2: " اختار صفحة ممثل الجهة ",
      RepresentativeStatus: "  صفة ممثل الجهة ",
      writeHere: "اكتب هنا...",
      writeIDType: "اختر نوع الهوية",
      writeIdNo: "ادخل رقم الهوية",
      clientEmail: "ادخل البريد الإلكتروني",
      choose: 'اختر',
      chooseClient: 'اختر العميل',
      chooseContractStatus: 'اختر حالة العقد',
      Court: 'محكمة',
      Committee: 'لجنة',
      courtType: 'نوع الجهات',
      notaddMorefive: 'الحد الأقصى للموظفين المكلفين 5',
      Add: 'إضافة',
      AddTeam: ' اضافة فريق',
      addNewCase: ' قضية جديدة',
      issueNo: 'رقم القضية',
      issueStatus: 'حالة القضية',
      court: 'المحكمة',
      issueType: 'نوع القضية',
      chooseIssueType: 'اختر نوع القضية',
      subIssueType: 'نوع القضية الفرعي',
      chooseSubIssueType: 'اختر نوع القضية الفرعي ',
      issueTeam: 'المكلفين',
      issueList: 'قائمة القضايا',
      addNew: 'قضية جديدة',
      All: 'الكل',
      Individual: 'فرد',
      Special: 'خاص',
      Governmental: 'حكومي',
      Workers: 'عمالية',
      Criminal: 'جنائية',
      PersonalConditions: 'أحوال شخصية',
      Civilian: 'مدنية',
      Administrative: 'إدارية',
      Commercial: 'تجارية',
      Other: 'أخرى',
      Active: 'نشط',
      UnderConsideration: 'قيد النظر',
      Inprogress: 'جاري العمل',
      Finished: 'منتهية',
      Pending: 'قيد النظر',
      Approval: 'موقفة',
      Canceled: 'مشطوبة',
      UnderReview: 'قيد المراجعة',
      Details: 'استعراض',
      edit: 'تعديل بيانات',
      glsat: 'الجلسات',
      log: 'سجل القضية',
      issueDetail: 'تفاصيل القضية',
      issueInfo: 'بيانات القضية',
      mainCourt: 'المحاكم الرئيسية',

      subCourt: 'المحاكم الفرعية',
      issueDate: 'تاريخ قيد القضية',
      issueEndDate: 'تاريخ انتهاء القضية',
      appStatus: 'حالة القضية',
      chooseAppStatus: 'اختر حالة القضية',
      Inactive: 'غير نشط',
      issueSubject: 'موضوع الدعوى',
      branchName: 'اسم الفرع',
      assignedName: 'اسم المُكَلَف',
      assignedName2: 'نوع التكليف',
      assignedName3: ' الفريق المكلف',
      Applicant: 'مقدم الطلب',
      applicantType: 'نوع مقدم الطلب',
      idNo: 'الهوية الوطنية/الإقامة',
      nationality: 'الجنسية',
      Defendant: ' مدعى عليه',
      attachments: 'المرفقات',
      fileName: 'اسم الملف',
      uploadDate: 'تاريخ الرفع',
      uploadBy: 'رفع بواسطة',
      download: 'تحميل',
      Plaintiff: 'المدعي',
      ApplicationReview: 'ملخص القضية',
      select: 'اختر  من هنا',
      select2: 'اختر نوع الجهة',
      NoMaxLength: 'لا يقبل أكثر من 50',
      onlyNumber: 'أرقام فقط ',
      Client: 'العميل',
      ClientType: 'صفة العميل',
      returnText: ' إعادة النص',
      SaveAndContinue: 'حفظ و متابعة',
      Save: 'حفظ',
      BranchName: 'اسم الفرع',
      BranchName2: ' مكتب/فرع',
      BranchName3: 'اكتب اسم الفرع',
      BranchName4: 'اختار اسم الفرع',

      Back: 'رجوع',
      Cancel: 'إلغاء',
      delete: 'حذف',
      PlaintiffType: 'صفة المدعي',
      IndividualType: 'فرد',
      GovernmentEntity: ' جهة حكومية',
      PrivateEntity: ' جهة خاصة',
      ApplicantsName: 'اسم مقدم الطلب',
      IDType: 'نوع الهوية',
      PersonalID: 'البطاقة الشخصية',
      Passport: 'جواز سفر',
      DefendantType: 'صفة المدعى عليه',
      Evidence: 'الأسانيد وبيانات القضية ',
      Category: 'التصنيف ',
      Attachment: 'المرفق  ',
      Document: 'اسم المستند',
      UploadDate: 'تاريخ الرفع',
      SupportingDocuments: 'الملفات الداعمة',
      Name: 'اسم الرافع ',
      Case: 'القضية',
      upload: 'رفع',
      fileType: 'نوع المرفق',
      caseAddSuccess: 'تم إضافة القضية بنجاح',
      caseEditSuccess: 'تم تعديل القضية بنجاح',
      cancel: 'إلغاء',
      save: 'حفظ',
      caseAttachment: 'الملفات الداعمة للقضية',
      reviewTitle: 'مراجعة القضية',
      reviewSubTitle: 'الرجاء مراجعة القضية قبل اعتماده',
      askEdit: 'هل تريد بالتأكيد تعديل القضية ؟',
      reason: 'سبب التعديل',
      atLeastOne: 'يجب إضافة اسم واحد على الأقل',
      haveAssigned: 'لا يمكن إضافة نفس الموظف مرتين ',
      name: 'الاسم',
      fileManager: 'مدير ملف القضية',
      editSuccess: 'تم إضافة مكلفين بنجاح',
      editText: 'تعديل النص',
      GeneralCourt: 'محاكم/قضاء عام',
      AdministrativeCourt: 'محاكم/ قضاء إداري',
      Representative: 'النيابة',
      PoliceStation: 'مراكز الشرطة',
      Prisons: 'السجون',
      MinistryOfHumanResources: 'تسوية ودية',
      otherCourtType: 'نوع الجهات (أخرى)',
      otherCommittee: 'اللجان (أخرى)',
      otherCourt: 'المحاكم الرئيسية (أخرى)',
      otherSubCourt: 'المحاكم الفرعية (أخرى)',
      confirmDelteBranch: 'هل أنت متأكد من تغيير الفرع و حذف المكلفين؟',
      NotFoundEmployees: 'لا يوجد موظفين في هذا الفريق',
    },
    addClient: {
      copydone: 'تم نسخ بيانات العميل بنجاح',
      CommercialRegistrationNumber: 'رقم السجل التجاري',
      copy: 'نسخ بيانات العميل ',
      actions: 'إجراءات',
      newClient: ' عميل جديد',
      Individual: 'فرد',
      Special: 'خاص',
      business: 'قطاع الأعمال',
      Active: 'نشط',
      NotActive: 'غير نشط',
      clientName3: 'اسم العميل',
      clientName: 'اسم المدعي',
      clientName2: 'اسم المدعي عليه',
      clientEmail: 'البريد الإلكتروني',
      clientPhoneNumber: 'رقم الجوال',
      clientStatus: 'حالة العميل',
      commericalName: 'الاسم التجاري',
      commericalNumber: 'رقم السجل التجاري',
      addSuccessfuly: 'تم إضافة العميل بنجاح',
      editSuccessfuly: 'تم تعديل العميل بنجاح',
      CommirecalNoMaxLength: 'السجل التجاري يجب أن لا يزيد عن ١٠ أرقام',
      EmailPattern: 'الرجاء إدخال عنوان بريد إلكتروني صحيح.',
      natioality: 'الجنسية',
      nationalityIdRequired: 'الرجاء اختيار الجنسية',
      nationalIdRequired: 'الرجاء اختيار نوع الهوية',
      idNo: 'رقم الهوية',
      IDType: 'نوع الهوية',
      PersonalID: 'البطاقة الشخصية',
      Passport: 'جواز سفر',
      NationalIdentity: 'الهوية الوطنية',
      RegularResidence: 'اقامة نظامية',
      GulfNationalIdentity: 'هوية وطنية خليجية',
      WorkAndMobilityCard: 'بطاقة عمل وتنقل',
      branchName: 'اسم الفرع',
      branchIdRequired: 'الرجاء اختيار الفرع',
      uploadId: 'إرفاق الهوية الوطنية',
      uploadAddress: 'إرفاق العنوان الوطني',
      address: 'العنوان الوطني',
      birthDay: 'تاريخ الميلاد',
      attachment: 'المرفقات',
      classification: 'التصنيفات',
      classificationRepeated: 'تم إرفاق المستند من قبل',
      attachmentRequired: 'يجب إرفاق جميع المستندات المطلوبة',
      download: 'تحميل',
      PersonalId: 'البطاقة الشخصية',
      Accommodation: 'الإقامة',
      Visa: 'الفيزا',
      Other: 'أخرى',
      Other2: 'صفة ممثل جهة آخري',
      BoardDirector: 'رئيس مجلس الادارة',
      CEO: 'رئيس تنفيذي',
      LegalAdvisor: 'مستشار قانوني ',
      Manager: 'مدير ',
      Accountant: 'محاسب'
    },
    client: {
      clientManagement: 'إدارة العملاء',
      representativeName: 'اسم ممثل الجهة',
      representativeName2: 'اكتب اسم ممثل الجهة',

      RepresentativeId: ' رقم هوية ممثل الجهة',
      TypeRepresentative: ' نوع هوية ممثل الجهة',
      clientName: 'اسم العميل',
      clientName2: 'اكتب اسم العميل',
      clientEmail: 'البريد الإلكتروني',
      clientStatus: 'حالة العميل',
      clientSearch: 'اسم العميل أو البريد الإلكتروني',
      All: 'الكل',
      Active: 'نشط',
      NotActive: 'غير نشط',
      clientList: 'قائمة العملاء',
      addNew: 'عميل جديد',
      confirmModalTitle: 'تغيير حالة العميل',
      activeMessage: 'هل تريد بالتأكيد تغيير حالة العميل ؟',
      Details: 'استعراض',
      edit: 'تعديل البيانات',
      clientDetails: 'بيانات العميل',
      clientType: 'نوع العميل',
      Individual: 'فرد',
      Special: 'قطاع الأعمال',
      Governmental: 'حكومي',
      clientPhone: 'رقم الجوال',
      Back: 'إلغاء',
      editbtton: 'تعديل',
      clientInfo: 'بيانات العميل',
      attachment: 'المرفقات',
    },
    workDuty: {
      workDutyManagement: 'إدارة المهام',
      projectManagement: 'إدارة المشاريع',
      accountingSummary: "الملخص المحاسبي",
      workDutyName: 'اسم المهمة',
      search: 'البحث',
      plzChoose: 'اختر من هنا',
      workDutyStatus: 'حالة المهمة',
      All: 'الكل',
      Active: 'نشط',
      Deferred: 'مؤجلة',
      Finished: 'منتهية',
      Canceled: 'ملغية',
      Pending: 'معلقة',
      NotFound: 'لا توجد بيانات متاحة',
      startDate: 'تاريخ بداية المهمة',
      endDate: 'تاريخ نهاية المهمة',
      user: 'العميل',
      caseStatus: 'حالة التفعيل',
      workDutyTitle: 'قائمة المهام',
      addNew: 'مهمة جديدة',
      total: 'الإجمالي',
      confirmModalTitle: 'تغيير حالة المهمة',
      activeMessage: 'هل تريد بالتأكيد تغيير حالة المهمة ؟',
      workDutyDetail: 'تفاصيل المهمة',
      workDutyInfo: 'بيانات المهمة',
      InActive: 'غير نشط',
      changeAccountStatus: 'تم تغيير حالة المهمة',
      general: 'عام',
      relatedCase: 'مرتبط بقضية',
      Subject: 'تفاصيل المهمة',
      caseNo: 'رقم القضية المرتبطة',
      workDutyType: 'نوع المهمة',
      caseAddSuccess: 'تم إضافة المهمة بنجاح',
      assignedSuccess: 'تم إضافة مكلفين بنجاح',
      caseEditSuccess: 'تم تعديل المهمة بنجاح',
      endDateValidation: 'يجب أن يكون تاريخ النهاية بعد تاريخ البدء ',
    },
    AddEditcontract: {
      contractManagement: 'إدارة العقود',
      contract: 'العقد',
      subTitle: 'جميع المشتركين في موقعك هنا',
      search: 'البحث',
      contractAddress: 'عنوان العقد',
      plzChoose: 'اختر من هنا',
      contractStatus: 'حالة العقد',
      All: 'الكل',
      Active: 'نشط',
      Deferred: 'مؤجلة',
      Finished: 'منتهية',
      Canceled: 'ملغية',
      Pending: 'معلقة',
      NotFound: 'لا توجد بيانات متاحة',
      startDate: 'تاريخ بداية العقد',
      endDate: 'تاريخ نهاية العقد',
      user: 'العميل',
      caseStatus: 'حالة التفعيل',
      contractTitle: 'قائمة العقود',
      addNew: 'عقد جديد',
      total: 'الإجمالي',
      confirmModalTitle: 'تغيير حالة العقد',
      activeMessage: 'هل تريد بالتأكيد تغيير حالة العقد ؟',
      contractDetail: 'تفاصيل العقد',
      contractInfo: 'بيانات العقد',
      InActive: 'غير نشط',
      contractSubject: 'تفاصيل و بنود العقد',
      contractTeam: 'الفريق المكلف',
      remember: 'تذكير',
      timeRemain: 'أيام قبل انتهاء العقد ',
      notifyAssigned: 'تذكير المكلفين',
      contractAddSuccess: 'تم إضافة العقد بنجاح',
      contractEditSuccess: 'تم تعديل العقد بنجاح',
      Days: 'أيام',
      endAfterStart: 'يجب أن يكون تاريخ النهاية بعد تاريخ البدء ',
      contractName: 'أدخل عنوان العقد',
      contractTerm: 'أدخل تفاصيل العقد',
      enterStartDate: 'أدخل تاريخ بداية للعقد',
      enterEndDate: 'أدخل تاريخ نهاية للعقد',
      enterDetails: 'أدخل تفاصيل العقد',
      contractDetails: "التفاصيل المالية للعقد",
      anyFees: "هل يوجد اتعاب ؟",
      yes: "نعم",
      no: "لا",
      ContractValue: "قيمة العقد (الاتعاب)",
      deposit: "قيمة المُقدم",
      FinalAmount: "قيمة المؤخر",
      installments: "الدفعات",
      installmentNumber: "رقم الدفعة",
      installmentAmount: "قيمة الدفعة",
      reason: "السبب",
      ContractValuePlaceholder: 'ادخل قيمة العقد (الاتعاب)',
      Currency: 'ر.س', // Saudi Riyal
      depositPlaceholder: 'ادخل قيمة المُقدم',
      amountOption: 'مبلغ ر.س',
      percentageOption: 'نسبة ٪',
      FinalAmountPlaceholder: 'ادخل قيمة المؤخر',
      installmentNumberPlaceholder: 'ادخل رقم الدفعة',
      installmentAmountPlaceholder: 'ادخل قيمة الدفعة',

      reasonPlaceholder: 'مثال : مبادرة اجتماعية',


    },
    addWorkDuty: {
      Add: 'إضافة',
      workDuty: 'مهمة',
      edit: 'تعديل',
      workDutyDetail: 'بيانات المهمة',
      workDutyTeam: 'المكلفين',
      All: 'الكل',
      Active: 'نشط',
      Deferred: 'مؤجلة',
      Finished: 'منتهية',
      Canceled: 'ملغية',
      Pending: 'معلقة',
      NotFound: 'لا توجد بيانات متاحة',
      startDate: 'تاريخ القيام بالمهمة',
      endDate: 'تاريخ نهاية المهمة',
      workDutyName: 'اسم المهمة',
      workDutyNameAnother: 'اسم المهمة',
      WriteworkDutyNameAnother: 'اكتب اسم المهمة',
      workDutyStatus: 'حالة المهمة',
      workDutyType: 'نوع المهمة',
      general: 'عام',
      relatedCase: 'مرتبط بقضية',
      Subject: 'تفاصيل المهمة',
      WriteSubject: ' اكتب تفاصيل المهمة ',
      caseNo: 'رقم القضية المرتبطة',
      ApplicationReview: 'مراجعة المهمة',
      reviewSubTitle: 'الرجاء مراجعة المهمة قبل اعتماده',
    },
    sitting: {
      sittingManagement: 'إدارة الجلسات',
      CourtSessions: 'جلسات',
      CourtSession: 'الجلسة',
      CourtSessionDetails: 'بيانات الجلسة',
      CourtSessionManagement: 'إدارة الجلسات',
      CourtSessionName: 'اسم الجلسة',
      CourtSessionTitle: 'عنوان الجلسة',
      CourtSessionplace: 'مكان انعقاد الجلسه',
      sittingLink: 'رابط الجلسة',
      sittingStatus: 'حالة الجلسة',
      startDate: 'تاريخ بدء الجلسة',
      startTime: 'وقت بدء الجلسة',
      list: 'قائمة الجلسات',
      addNew: 'إضافة جلسة جديدة',
      report: 'محضر الجلسة ',
      sittingOfCase: 'ربط مع قضية',
      reportAddress: 'عنوان المحضر ',
      reportDate: 'تاريخ المحضر ',
      reportSubject: 'نص المحضر ',
      pdfView: 'تحميل المحضر pdf',
      confirmModalTitle: 'تأكيد',
      activeMessage: 'هل تريد بالتأكيد تغيير حالة الجلسة',
      send: 'إرسال المحضر للعميل',
      sendSuccess: 'تم إرسال المحضر للعميل بنجاح',
      log: 'سجل الجلسات',
      changeAccountStatus: 'تم تغيير حالة الجلسة بنجاح',
      caseAddSuccess: 'تم إضافة الجلسة بنجاح',
      caseEditSuccess: 'تم تعديل الجلسة بنجاح',
      askEdit: 'هل تريد بالتأكيد تعديل الجلسة ؟',
      CourtSessionNumber: 'رقم الجلسة',
      courtType: 'نوع الجهة',
      sittingType: 'نوع الجلسة',
      Deferred: 'مؤجلة',
      All: 'الكل',
      Online: 'عن بعد ',
      Incourt: 'حضوري ',
      addReport: 'تم إضافة المحضر بنجاح',
      noAssigned: 'لا يوجد فريق مكلف',
      noReport: 'لا يوجد محضر  ',
      noAttachment: 'لا يوجد مرفقات',
      courts: 'المحاكم',
      Committees: 'اللجان',
      timeRemain: 'أيام قبل أنتهاء الجلسة ',
      Typehere: 'اكتب هنا',
      ApplicationReview: 'ملخص الجلسة',
      Finished: 'منتهية',
      Active: 'منعقدة',
      Scheduled: 'مجدولة',
      Postponed: 'مؤجلة',
    },
    contactUs: {
      contact: 'مهتّمون بسماع رسالتك ',
      label: "اختر وسيلة التواصل الأنسب لموضوعك لنجيب عليك في أقرب وقت.",

      purpose: 'الهدف من التواصل',
      Inquiry: 'استفسار',
      Suggestion: 'مقترح',
      Complaint: 'شكوى',
      name: 'الاسم',
      email: 'البريد الإلكتروني',
      message: 'نص الرسالة',
      send: 'إرسال',
      MessageType: 'نوع الرسالة',
      SuccessfulMessage: 'تم إرسال الرسالة بنجاح',
      contactNumber: "رقم التواصل",
      supportMail: "بريد الدعم الفني",
      infoMail: "بريد المقترحات",
      address: "العنوان"
    },
    privacy: {
      title: 'سياسة الخصوصية',
      label:
        'بإمكانك استخدام المنصة والاستفادة منها دون الكشف عن هويتك أو الكشف عن أي تفاصيل يمكن استخدامها لتحديدك كفرد معين يمكن التعرف عليه.',
      head1: 'مقدمة',
      body1:
        ' نحن في "قيد" نلتزم بحماية خصوصيتك وضمان أمان بياناتك الشخصية.تُوضح سياسة الخصوصية هذه كيفية جمع واستخدام وحماية المعلومات التي تقدمها عند استخدام خدمات نظام "قيد". نرجو منك قراءة هذه السياسةبعناية لفهم ممارساتنا المتعلقة بالخصوصية.',
      head2: 'جمع المعلومات',
      body20:
        'نقوم بجمع المعلومات بطرق مختلفة عند استخدامك لنظام "قيد"، بما في ذلك:',
      body21:
        '- المعلومات الشخصية: الاسم، البريد الإلكتروني، رقم الهاتف، وغيرها من المعلومات الأساسية.',
      body22: '- تفاصيل الحساب: مثل اسم المستخدم وكلمة المرور.',
      body23:
        '- المعلومات المتعلقة بالقضايا: بيانات القضايا والمستندات القانونية التي تقوم بإدخالها أو تحميلها إلى النظام.',
      body24:
        '- المعلومات التقنية: نوع الجهاز، نظام التشغيل، ونوع المتصفح، عنوان IP .',
      body25: '- معلومات حول كيفية استخدامك للنظام والتفاعل مع خدماتنا.',
      head3: 'استخدام المعلومات',
      body30: 'نستخدم المعلومات التي نجمعها للأغراض التالية:',
      body31:
        "- تقديم الخدمات: تمكينك من استخدام نظام 'قيد' وإدارة القضايا والمستندات، تحسين وتطوير خدماتنا لتلبية احتياجاتك.",
      body32:
        '- التواصل: إرسال إشعارات ومراسلات تتعلق بحسابك أو تحديثات النظام، الرد على استفساراتك وتقديم الدعم الفني.',
      body33:
        '- الأمان: حماية حسابك والمعلومات الشخصية من الوصول غير المصرح به أو الاستخدام غير القانوني، مراقبة وتحليل الأنشطة المشبوهة لمنع الاحتيال والانتهاكات.',
      body34: '- الامتثال القانوني: الامتثال للمتطلبات القانونية والتنظيمية.',
      head4: 'حماية المعلومات',
      body40:
        'نحن نلتزم باتخاذ جميع التدابير الأمنية اللازمة لحماية معلوماتك الشخصية، بما في ذلك:',
      body41:
        '- تشفير البيانات: استخدام تقنيات التشفير لحماية البيانات المنقولة والمخزنة.',
      body42:
        '- الوصول المحدود: تحديد الوصول إلى المعلومات الشخصية على الموظفين المخولين فقط.',
      body43:
        '- المراقبة والتحكم: مراقبة الأنظمة بانتظام لاكتشاف ومعالجة أي تهديدات أمنية.',
      head5: 'مشاركة المعلومات',
      body50:
        'نحن لا نشارك معلوماتك الشخصية مع أطراف ثالثة إلا في الحالات التالية:',
      body51: 'بموافقتك:',
      body52: '- عندما تمنحنا موافقة صريحة لمشاركة المعلومات.',
      body53:
        '- لخدمة مقدمي الخدمات: مع مزودي الخدمات الذين يساعدوننا في تشغيل النظام وتقديم خدماتنا، بشرط التزامهم بسياسات الخصوصية الخاصة بنا.',
      body54:
        '- للأغراض القانونية: عندما يكون الإفصاح عن المعلومات ضروريًا للامتثال للقوانين أو الإجراءات القانونية أو الطلبات الحكومية.',
      head6: 'حقوقك',
      body60: 'نحن نحترم حقوقك المتعلقة بمعلوماتك الشخصية، بما في ذلك:',
      body61:
        '- حق الوصول: يمكنك طلب الوصول إلى المعلومات الشخصية التي نحتفظ بها عنك.',
      body62:
        '- حق التصحيح: يمكنك طلب تصحيح أو تحديث معلوماتك الشخصية إذا كانت غير دقيقة أو غير مكتملة.',
      body63:
        '- حق الحذف: يمكنك طلب حذف معلوماتك الشخصية في بعض الحالات، مثل عندما لا تكون المعلومات ضرورية للأغراض التي جُمعت من أجلها.',
      body64:
        '- حق الاعتراض: يمكنك الاعتراض على معالجة معلوماتك الشخصية في بعض الحالات، مثل التسويق المباشر.',
      head7: 'التغييرات على سياسة الخصوصية',
      body7:
        'قد نقوم بتحديث سياسة الخصوصية من وقت لآخر. سنقوم بإعلامك بأي تغييرات جوهرية عن طريق نشر السياسة الجديدة على موقعنا وإرسال إشعار  عبر البريد الإلكتروني. نوصي بمراجعة سياسة الخصوصية بشكل دوري للبقاء على اطلاع على كيفية حماية معلوماتك.',
      head8: 'الاتصال بنا',
      body80:
        'إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية هذه أو ممارساتنا المتعلقة بالخصوصية، يرجى التواصل معنا عبر:',
      body81: ' -البريد الإلكتروني: ',
      body82: '-الهاتف: ',
      body83: '-العنوان :',

      body84:
        'نشكر لك ثقتك في "قيد" ونتعهد بالحفاظ على خصوصيتك وأمان معلوماتك الشخصية.',
    },
    question: {
      title: 'الأسئلة الشائعة',
      label:
        'إذا كان لديك المزيد من الأسئلة  أو تحتاج إلى مساعدة إضافية، لا تتردد في',
      contact: ' التواصل معنا. ',
      label2: 'نحن هنا لخدمتك وضمان تجربة سلسلة وممتعة مع نظام "قيد"',
    },
    home: {
      qyd: ' قيد',
      demo: 'اطلب عرضًا توضيحيًا',
      head: 'قيد الشريك الرقمي لإدارة أعمالك القانونية',
      getQyd: 'تجربة مجانية',
      head2: 'قيد شريكك الذكي',
      body1: 'نقيّد أعمالك بتقنيات مبتكرة لرفع كفاءة الأعمال القانونية والإدارية في منصة واحدة،',
      body2: " تختصر الوقت وتوفر التكاليف وترفع من ثقة عملائك.",
      join: 'تجربة مجانية',
      firstHead: 'لمن قيد ؟',
      systemInte: 'نظامك المتكامل',
      firstBody:
        'نحن نقدم استشارات قانونية متخصصة وخدمات شاملة لضمان حماية حقوق عملائنا',
      individual: 'الأفراد',
      individual1: 'إدارة القضايا',
      individual2: 'إدارة المهام ',
      individual3: 'إدارة الجلسات ',
      individual4: 'إدارة العقود',
      individual5: 'تبسيط إدارة القضايا',
      individual6: 'توفير الوقت عبر الأتمتة',
      individual7: 'تحكم كامل في المواعيد والمستندات',
      individual8: 'تعزيز الخصوصية والأمان',

      individual9: 'إدارة العملاء',
      individual10: 'إدارة الوثائق',
      individual11: 'إدارة الموظفين',
      individual12: 'إدارة الفروع',
      individual13: 'إدارة العقود',
      individual14: 'إدارة الوكالات',
      individual15: 'إدارة المكتب الرئيسي',

      laywer1:
        'نجمع قضاياك في لوحة تحكم واحدة ونقيّد كل قضية بكل تفاصيلها، مع إمكانية تحديثها بسهولة، لتركّز على متابعة تقدّمك.',
      laywer2:
        'أدر مهام موظفيك عبر المنصة، ستُرسل لهم تلقائياً إشعارات تذكيرية بالمهام والمواعيد المستقبلية، لتتابع تقدّم العمل وإنجاز المهام من لوحة تحكمك.',
      laywer3:
        'ننظّم مواعيد الجلسات القضائية بجدولتها،  وتذكير الموظفين المُكّلفين بتفاصيلها ومتطلباتها المرتبطة بالقضايا للسعي على مصالح العملاء. ',
      laywer4:
        'بملفات رقمية منظّمة، أدر عقود موظفيك وعقود عملائك القائمين وشركاء مكتبك في مكان واحد، بأدوات تخزينية متقدّمة وآمنة. ',
      laywer5:
        'أدواتنا تسهل تنظيم جميع القضايا والمستندات في مكان واحد بواجهة بسيطة، مما يوفر الوقت والجهد في عمليات البحث.',
      laywer6:
        'أتمتة المهام الروتينية مثل التذكيرات ومتابعة الجلسات وإنشاء التقارير، تتيح لك التركيز أكثر على مخرجاتك القانونية.',
      laywer7:
        'إدارة مواعيد الاجتماعات والجلسات مع إشعارات تذكيرية، والوصول إلى المستندات بسهولة من أي جهاز وفي أي وقت.',
      laywer8:
        'تقنيات التشفير المتقدمة تضمن حماية المستندات القانونية، مع تحكم كامل في من يمكنه الوصول إليها للحفاظ على السرية.',

      laywer9:
        'نحفظ بيانات عملائك بتسجيلها في قائمة منظّمة لسهولة إدارتها والوصول إليها، بما يضمن فاعلية في التواصل وتقديم الخدمات.  ',
      laywer10:
        ' نوفر لك مساحة كافية وآمنة لوثائق ومستندات عملائك في خوادم تخزين خاصة داخل السعودية، مع إمكانية تصنيفها عبر أدوات تقنية متقدمة. ',
      laywer11:
        'سجّل موظفيك حسب عدد المستخدمين لديك، وتابع تكليفهم بالأعمال القانونية من خلال المنصة بإجراءات تنظيمية تساعدهم على الإنجاز.',
      laywer12:
        'لزيادة التنظيم الإداري لمكتبك ذي الفروع المتعددة، بإمكانك تخصيص لوحة تحكم خاصة بكل فرع لإدارته بشكل مستقل عن غيره من الفروع. ',
      laywer13:
        'بملفات رقمية منظّمة، أدر عقود موظفيك وعقود عملائك القائمين وشركاء مكتبك في مكان واحد، بأدوات تخزينية متقدّمة وآمنة. ',
      laywer14:
        'ننظم الوكالات التي تم تفويضها لك من العملاء، حسب نوعها العام، أو الخاص بقضية معينة، مع تاريخ البداية والنهاية بسهولة في البحث والوصول. ',
      laywer15:
        'مكتبك الرقمي معك في كل مكان، بإمكانك إدارة قضاياك ومواعيدك و مستنداتك كلها من لوحة تحكم واحدة في أي وقت. ',

      whyQydTheBest: 'لماذا قيد الشريك الأمثل لأعمالك ؟  ',
      laywer: 'المحامي',
      laywerBody: 'يمثل الأفراج أو الكيانات القانونية أمام المحاكم',
      consultant: 'المستشار القانوني',
      consultantBody: 'يقدم الاستشارات والتوجيه القانوني',
      entity: 'الجهات',
      representive: 'الممثل النظامي',
      LegalManagement: 'الإدارة القانونية',
      representiveBody: 'يمثل الكيان القانوني في الإجراءات الرسمية',
      law: 'مكاتب المحاماة',
      lawBody: 'مؤسسات تقدم خدمات قانونيه متكاملة',
      legal: 'الإدارات القانونية في الشركات',
      legalBody: 'نتولي حماية مصالح الشركة قانونيا',
      services: 'تجربة مجانية',
      howWork: 'كيف نعمل؟',
      step1:
        ' توفير التكاليف التشغيلية للأعمال من خلال أتمتة كاملة لنظام العمل.',
      step2: 'نظام شامل ومتكامل للاستخدام في أي وقت وأي مكان. ',
      step3: 'خوادم تخزين خاصة بتقنيات تشفير عالية الأمان داخل السعودية.',
      step4: 'مركز مساعدة مستعد لإجابة الاستفسارات ومعالجة تحديات الاستخدام.',
      step5: 'تحديثات تقنية مستمرة تضمن قوة الأداء و استدامة الخدمة.',
      step1Content:
        'الحفاظ على نظرة عامة واضحة لجميع المسائل القانونية الجارية | ,قم بتحديد المواعيد النهائية المهمة ومراقبتها بسهولة | ,الحفاظ على نظرة عامة واضحة لجميع المسائل القانونية الجارية',
      step2Content:
        'الحفاظ على نظرة عامة واضحة لجميع المسائل القانونية الجارية | ,قم بتحديد المواعيد النهائية المهمة ومراقبتها بسهولة | ,الحفاظ على نظرة عامة واضحة لجميع المسائل القانونية الجارية',
      step3Content:
        'الحفاظ على نظرة عامة واضحة لجميع المسائل القانونية الجارية | ,قم بتحديد المواعيد النهائية المهمة ومراقبتها بسهولة | ,الحفاظ على نظرة عامة واضحة لجميع المسائل القانونية الجارية',
      servicesBody:
        'استفد من الخدمات التنظيمية الرقمية لضمان كفاءة أعمالك القانونية',
      section1:
        'نقيّد كل قضاياك في لوحة تحكم واحدة، مع إمكانية تحديث التفاصيل وربط الملفات و جدولة الجلسات بسهولة.',
      section2:
        'تابع تقدّم أعمالك بإشعارات تذكيرية بالمهام والمواعيد المستقبلية، لتتابع تقدّم العمل من لوحة تحكمك.',
      section3:
        'نوفر لك مساحة كافية وآمنة لوثائق ومستندات عملائك بخصوصية تامة في خوادم تخزين خاصة داخل السعودية.',
      selectPlan: 'اشترك الآن بتجربة مجانية لمدة ١٤ يوماً ',
      planDesc: 'تمتع بوصول كامل لخدمات المنصة لمدة 14 يوماً مجاناً ',
      mangTime: "أدر أعمالك",
      packges: 'الباقات',
      qydServices: 'خدمات قيد',
      Annual: 'سنوي',
      Monthly: 'شهري',
      prominentHead: 'لماذا قيد الشريك الأمثل لأعمالك ؟',
      sol: 'مميزات المنصة',
      prominentBody: 'قيد تقدّم لك حلولاً مستدامة ',
      prominentText1: 'وثائق مبعثرة، وأماكن تخزين محدودة.',
      prominentText1Body: 'حفظ الوثائق والمستندات في مساحة تخزين كافية وآمنة.',
      prominentText2: 'جدول مزدحم بالمهام والمواعيد. الحل',
      prominentText2Body: 'نظام جدولة وتذكير مخصص.',
      prominentText3: 'ضعف التواصل بين موظفيك.',
      prominentText3Body: 'تواصل مباشر ومتابعة مستمرة.',
      prominentText4: 'كثرة التنقّل مع الحاجة لمتابعة أعمال المكتب.',
      prominentText4Body: 'لوحة تحكم رقمية تدار في أي وقت ومن أي مكان.',
      prominentText5: 'خلل في إدارة بيانات عملاءك.',
      prominentText5Body: 'تسجيل بيانات عملائك في قائمة منظمة.',
      more: 'المزيد من خدمات قيد',
      FAQ1: 'هل يدور في ذهنك سؤال؟',
      FAQ2: 'إجابات لأسئلتك ',
      Q1: 'هل يمكنني الوصول إلى خدمات قيد من أي مكان؟ ',
      Q2: 'كيف يتم حفظ وتأمين بياناتي في نظام قيد؟ ',
      Q3: 'ماذا يحدث لبياناتي إذا قررت إلغاء الاشتراك؟ ',
      Q4: 'كيف يمكنني التواصل مع مركز المساعدة',
      Q5: 'ماهي طرق الدفع المتاحة لرسوم الاشتراك؟ ',
      Q1Desc:
        'نعم، منصة قيد هي منصة قائمة على نظام سحابي، مما يتيح لك الوصول إلى بياناتك وإدارة قضاياك من أي مكان وفي أي وقت عبر شبكة الإنترنت. ',
      Q2Desc:
        'نحن نستخدم تقنيات تشفير متقدمة لحماية بياناتك، ونوفر نسخة احتياطية دورية منها ليتمكن الموظفون المخولون فقط من الوصول إلى البيانات، مما يضمن سرية وأمان معلوماتك. ',
      Q3Desc:
        'في حالة إلغاء الاشتراك، يمكنك طلب نسخة من جميع بياناتك المحفوظة في نظام قيد، نضمن لك الحصول عليها بشكل سلس وآمن. ',
      Q4Desc:
        'يمكنك التواصل مع مركز المساعدة عبر البريد الالكتروني (support@qyd.sa)،  فريق الدعم الفني موجود على مدار ساعات العمل لمساعدتك والإجابة على جميع استفساراتك.',
      Q5Desc:
        'يمكنك الدفع إلكترونياً عبر طرق دفع متنوعة حسب اختيارك من  بطاقات مدى، فيزا. ',
      Staytuned: 'ترقب التحديثات',
      StaytunedDesc:
        'في قيد نحن متحمسون لدمج عملك. ,قم بالتسجيل ليتم تنبيهك بتحديثات المنتج.',
      email: 'البريد الإلكتروني',
      submit: 'إرسال',
      inputFooter: 'لمعرفة كيفية تعامل قيد مع معلوماتك،',
      inputFooter2: 'يرجى الاطلاع على ',
      inputFooter3: 'سياسة الخصوصية',
      legalManagement: 'لماذا منصة قيد؟',
      legalManagementLabel: 'نلتزم بالشفافية والاحترافية في جميع تعاملاتنا',
      cardTitle1: 'التخزين',
      cardBody1: ' خوادم تخزين خاصة داخل السعودية',
      cardTitle2: 'الأمان',
      cardBody2: 'تقنيات تشفير عالية الأمان',
      cardTitle3: 'البيانات',
      cardBody3: 'نسخ احتياطي تلقائي للبيانات',
      cardTitle4: 'الدعم',
      cardBody4: 'دعم فني على مدار ساعات العمل',
      header5: 'اشترك الآن بتجربة دون رسوم',
      label5:
        ' باقات مرنة تتناسب مع احتياجاتك وتنظم أعمالك القانونية بتجربة مجانية',
      WhoTarget: 'من نستهدف؟',
      Entities1: ' الأفراد',
      Entities2: ' الجهــات',
      Lawyer: 'المحامي',
      LegalAdvisor: 'المستشار القانون',
      InHouse: 'مكتب المحاماة',
      Statutory: 'ممثل نظامي',
      Law: 'الإدارة القانونية في الشركة ',
      meetAllNeeds: 'نقدّم حلولاً مستدامة لرواد القطاع القانوني',
      window: ' ندعم مسيرتك القانونية بحلولنا الرقمية  ',
      window2: 'نرفع كفاءة أعمالك بالتحوّل الرقمي',
      Lawyer1: 'محامي',
      Lawyer2: ' مستشار قانوني',
      Lawyer3: 'محامي متدرب',
      Lawyer4: 'باحث قانوني',
      saveUpTo: 'وفّر حتى 30%',

      // section 2
      section2Slider: {
        name1: "تسهيل إدارة القضايا",
        name2: "اختصار الوقت بأتمتة العمليات",
        name3: "تحكم كامل في المواعيد والمستندات",
        name4: "الحفاظ على الخصوصية والأمان",
        name5: "تبسيط العمليات القانونية المعقدة",
        name6: "تعزيز التعاون بين الموظفين",
        name7: "متابعة دقيقة لإدارة الحوكمة",
        name8: "تكامل مع الأنظمة الأخرى",


        body1: "من خلال أدوات قيد نظّم جميع القضايا والمستندات في مكان واحد بواجهة سهلة الاستخدام، ووفر الوقت والجهد في تنظيم أعمالك .",
        body2: "أتمتة المهام الروتينية مثل التذكيرات ومتابعة الجلسات وإنشاء التقارير، تتيح لك التركيز أكثر على مخرجاتك القانونية.",
        body3: "إدارة مواعيد الاجتماعات والجلسات مع إشعارات تذكيرية، والوصول إلى المستندات بسهولة من أي جهاز وفي أي وقت.",
        body4: "تقنيات التشفير المتقدمة تضمن حماية المستندات القانونية، مع تحكم كامل في إمكانية الوصول إليها للحفاظ على السرية. ",
        body5: "أدوات الأتمتة والتحليل تقلل من الزمن المستغرق في إدارة القضايا، مراجعة العقود، وإدارة النزاعات، مع تقارير دقيقة لتحسين الأداء.",
        body6: "مشاركة المستندات وتحديث المعلومات بشكل آني في منصة موحدة لجميع الموظفين، مما يعزز العمل الجماعي ويقلل من الأخطاء.",
        body7: " نساعدك على مراقبة الامتثال، السياسات، والإجراءات لضمان تطبيق أعلى معايير الحوكمة القانونية.",
        body8: "يتكامل النظام بسلاسة مع أنظمة تخطيط الموارد للشركات، مما يسهل تدفق البيانات بين الإدارات المختلفة دون تعقيد. ",
      }

    },
    footer: {
      aboutQyd: 'قيد لتقنية المعلومات',
      ryad: 'الرياض، المملكة العربية السعودية.',
      contactEmail: 'بريد التواصل:',
      contactNumber: 'رقم المبيعات:',
      QuickLinks: ' روابط سريعة',
      address:
        'العنوان الوطني: طريق الملك عبدالله ـ حي المغرزات ـ الرياض ـ المملكة العربية السعودية ـ 2604-2039 ',
      links: {
        home: 'الصفحة الرئيسية',
        aboutQyd: 'حول قيد',
        services: 'الخدمات',
        packages: 'الحزم',
      },
      StayConnected: 'مقرنا',

      whyQyd: 'لماذا قيد',
      privacy: 'سياسة الخصوصية',
      privacy2: 'سياسة الإلغاء والاسترجاع.',
      question: 'الأسئلة الشائعة',
      terms: 'الشروط و الأحكام',
      slp: 'سياسة مستوى الخدمة ',
      contact: 'للتواصل',
      copyright: 'قيد لتقنية المعلومات 2024 ⓒ',
    },
    attorny: {
      attornyManagement: 'إدارة الوكالات',
      pleaseSelectPackage: 'الرجاء تحديد الباقة قبل المتابعة',
      attornies: 'الوكالات',
      subTitle: 'جميع المشتركين في موقعك هنا',
      search: 'البحث',
      attornyNumber: 'رقم الوكالة',
      enterAttornyNumber: 'ادخل رقم الوكالة',
      attornyAddress: 'عنوان الوكالة',
      enterAttornyAddress: 'ادخل عنوان الوكالة',
      plzChoose: 'اختر من هنا',
      attornyStatus: 'حالة الوكالة',
      enterAttornyStatus: 'اختر حالة الوكالة',
      CheckInsertData: 'التاكد من ان البيانات صحيحية',
      All: 'الكل',
      Active: 'سارية',
      Deferred: 'مؤجلة',
      Finished: 'منتهية',
      Canceled: 'مفسوخة',
      PartiallyDissolved: 'مفسوخة جزئيا',

      Pending: 'معلقة',
      NotFound: 'لا توجد بيانات متاحة',
      startDate: 'تاريخ بداية الوكالة',
      endDate: 'تاريخ نهاية الوكالة',
      user: 'العميل',
      caseStatus: 'حالة التفعيل',
      attornyTitle: 'قائمة الوكالات',
      addNew: 'وكالة جديدة',
      total: 'الإجمالي',
      confirmModalTitle: 'تغيير حالة الوكالة',
      activeMessage: 'هل أنت متأكد من تغيير حالة الوكالة ؟',
      attornyDetail: 'تفاصيل الوكالة',
      attornyInfo: 'بيانات الوكالة',
      InActive: 'غير نشط',
      attornySubject: 'تفاصيل و بنود الوكالة',
      attornyTeam: 'الفريق المكلف',
      remember: 'تذكير',
      timeRemain: 'أيام قبل انتهاء الوكالة ',
      NotAuth: 'ليس لديك صلاحيةالإطلاع على هذه المعلومات',
      attorny: 'وكالة',
      attornyTerm: 'ادخل تفاصيل الوكالة',
      enterStartDate: 'ادخل تاريخ بداية للوكالة',
      enterEndDate: 'ادخل تاريخ نهاية للوكالة',
      enterDetails: 'ادخل تفاصيل الوكالة',
      changeAccountStatus: 'تم تغيير حالة الوكالة بنجاح',
      ApplicationReview: 'مراجعة الوكالة',
      reviewTitle: 'مراجعة الطلب',
      reviewSubTitle: 'الرجاء مراجعة الطلب قبل اعتماده',
      caseAddSuccess: 'تم إضافة الوكالة بنجاح',
      TeamddSuccess: 'تم إضافة الفريق بنجاح',
      TeamEditSuccess: 'تم تعديل الفريق بنجاح',
      NameOfTeamExist: ' اسم الفريق موجود بالفعل, من فضلك ادخل اسم جديد',

      caseEditSuccess: 'تم تعديل الوكالة بنجاح',
      MustAddAtLeastOneItem: 'يجب اختيار عنصر واحد علي الاقل',

    },

    package: {
      Seven: '7 ايام',
      list: 'قائمة الباقات',
      packageManagement: 'إدارة الباقات ',
      packageName: 'اسم الباقة',
      return: 'استرجاع',
      Search: 'البحث',
      packagePrice: 'سعر الباقة',
      discount: 'نسبة الخصم',
      packageStatus: 'حالة الباقة',
      packageDetails: 'تفاصيل الباقة',
      CustomizedPackage: 'باقة مخصصة ',
      GloablPackage: 'باقة عامة ',
      PackageType: 'نوع الباقة',
      packageEdit: 'تعديل الباقة',
      delete: 'حذف',
      addPackage: 'إضافة باقة جديدة',
      addCustomizeFreeTrialPackage: 'اضافة باقة تجربية مخصصة',
      accountType: 'نوع الحساب',
      lawyerOffice: 'مكتب محاماه',
      advisor: 'مستشار',
      packageNameAr: 'اسم الباقة باللغة العربية',
      packageNameEn: 'اسم الباقة باللغة الانجليزية',
      disCountRate: 'نسبة الخصم',
      price: 'سعر الباقة',
      maxEmployeeNo: 'أقصى عدد للموظفين',
      maxBranchNo: 'أقصى عدد للفروع',
      durationPackage: 'مدة الباقة ',
      select: 'اختر من هنا',
      ThreeMonths: 'ثلاث أشهر',
      Month: 'شهر',
      SixMonths: 'ستة أشهر',
      Year: 'سنة',
      fourteenDays: '14 يوم',
      TwentyOneDays: '21 يوم',
      ThirtyDays: '30 يوم',
      SixtyDays: '60 يوم',
      NinetyDays: '90 يوم',
      OneHundredEightyDays: '180 يوم',
      TwoHundredSeventyDays: '270 يوم',
      ThreeHundredSixtyDays: '360 يوم',
      packageConditions: 'خصائص الباقة',
      Desc: 'تشمل هذه الباقة ( إدارة العملاء _ إدارة العقود  ) يمكنك اختيار خصائص إضافية للباقة : -',
      inEnglish: 'هذا النص يجب أن يكون باللغة الانجليزية',
      inArabic: 'هذا النص يجب أن يكون باللغة العربية',
      Inactive: 'غير مفعل',
      All: 'الكل',
      Active: 'مفعل',
      changeConfirmation: '  تأكيد التغيير',
      changeText: 'هل تريد بالتأكيد تغيير الحالة؟',
      editTostar: 'تم تعديل الباقة بنجاح  ',
      changeToggleTostr: 'تم تعديل حالة الباقة بنجاح',
      AddedSuccessfully: ' تم إضافة الباقة بنجاح',
      viewPackage: 'تفاصيل الباقة',
      discountDes: 'خصم بنسبة',
      priceDes: 'سعر الباقة',
      ValueAddedTax: 'ضريبة القيمة المضافة ',
      packageDetailsTitle: "زيادة عدد المستخدمين وتوسيع الفروع بسهـــولة",
      packageDetailsTitle2: "يمكن زيادة عدد المستخدمين في الباقة لتكون",
      packageDetailsTitle3: "قيمة المستخدم الإضافي= عدد المستخدمين/ القيمة الكلية للباقة. ",
      packageDetailsTitle4: "الفرع الجديد= 150 ر.س ​",
    },
    payments: {
      payment: 'الدفع والتأكيد',
      description: 'تفاصيل الفاتورة ',
      formattedAmount: 'المبلغ الكلي المدفوع',
      PaymentByReceipts: 'الدفع عن طريق الايصالات',
      PaymentReceipts: 'فواتير الايصالات',
      PaymentReceipts2: 'قائمة المدفوعات',
      PaymentMoyasserReceipts: 'فواتير ميسر',
      electronicPayment: 'دفع الكترونية',
      bankTransfer: 'حوالة بنكية',
      buyPackage: 'أبدأ التجربة المجانية',

      pay2: 'الدفع الإلكتروني',
      paymentWay: 'طريقة الدفع',
      paymentMoyasarDescrption: 'طريقة الدفع',
      free: 'اشتراك مجاني',
      paymnetTitle: 'قائمة المدفوعات',
      invoiceNo: 'رقم الفاتورة',
      invoiceStatus: ' حالة الفاتورة',
      invoiceDate: ' تاريخ الفاتورة',
      endDate: 'تاريخ انتهاء الباقة',
      packageName: 'اسم الباقة',
      invoiceValue: ' قيمة الفاتورة',
      viewInvoice: ' استعراض الفاتورة',
      downloadReceipt: ' تحميل الإيصال',
      ViewRejection: ' استعراض سبب الرفض',
      invoicePayment: 'سداد الفاتورة',
      rejectionReason: 'سبب الرفض',
      canPayment: 'يمكنك إدخال بيانات التحويل لإجراء عملية الدفع',
      paymentNotice: "إشعار السداد",
      uploadPhoto: "يجب إرفاق صورة من الإيصال بعد التحويل حتى يتم قبول الطلب",
      uploadPhoto2: "ارفاق صورة الوصل",
      ibanNo: 'رقم ال (IBAN)  للتحويل من البنوك الأخرى',
      copy: "تم نسخ رقم الحساب بنجاح",
      raydBank: "بنك الرياض",
      accountTransfer: "رقم الحساب للتحويل من بنك الرياض",
      attachReceipt: 'إرفاق إيصال',
      clientName: 'اسم العميل',
      fromDate: 'من التاريخ ',
      toDate: 'إلى التاريخ',
      requestStatus: 'حالة الطلب',
      invoiceApprovement: 'اعتماد الإيصال',
      canApproveOrREject: 'يمكنك اعتماد أو رفض الإيصال مع ذكر سبب الرفض',
      rejectReceipt: 'رفض الإيصال',
      acceptReceipt: 'الموافقة على الإيصال',
      All: 'الكل',
      NotPaied: 'غير مسددة',
      WaitingForApproval: 'بانتظار الاعتماد',
      Paied: 'تم الدفع',
      Rejected: 'تم الرفض',
      Cancelled: 'منتهية',
      sar: 'ريال',
      reasonValidation: 'من فضلك اذكر سبب الرفض',
      changeSuccessfully: 'تم اعتماد الفاتورة بنجاح',
      valueAddedTaxng: ' ضريبة القيمة المضافة',
    },
    upgradePackage: {
      upgradePackage: 'إدارة الاشتراكات',
      accountType: 'نوع الحساب',
      LawyerOffice: 'مكتب محاماة',
      RegularRepresentative: 'ممثل نظامي',
      Advisor: 'مستشار',
      all: 'الكل',
      ThreeMonths: '3 شهور',
      SixMonths: '6 شهور',
      Year: 'سنة',
      Month: ' شهر واحد',
      employees: 'موظفين ',
      branches: 'فرع ',
      defaultText: 'تشمل هذه الباقة ( إدارة العملاء _ إدارة العقود )',
      SittingManagement: 'إدارة الجلسات',
      AgencyManagement: 'إدارة الوكالات',
      BranchManagement: 'إدارة الفروع',
      ClientManagment: 'ادارة العملاء',
      EmployeeManagment: 'ادارة الموظفين',
      ContractManagment: 'ادارة العقود',
      AccountingManagement: 'ادارة الملخص المحاسبي',
      LawsuitManagement: 'إدارة القضايا',
      TaskManagement: 'إدارة المهام',
      choosePackage: 'اشترك الآن',
      contactUs: 'تواصل معنا',
      contactUs2: 'صمم باقتك',
      currentPackage: 'الباقة الحالية',
      discount: 'وفر',
      invoiceDetails: 'تفاصيل الفاتورة',
      invoiceDate: 'تاريخ إنشاء الفاتورة',
      invoiceTime: 'وقت إنشاء الفاتورة',
      durationPackage: 'مدة الاشتراك',
      clientName: 'اسم المنشأة',
      serviceName: 'اسم مقدم الخدمة',
      agentAddress: 'عنوان المنشأة',
      licenseNumber: 'رقم السجل التجاري',
      taxNumber: 'رقم الضريبي',
      taxInvoice: 'فاتورة ضريبية',
      serviceCost: 'تكلفة الخدمة',
      discountRate: 'نسبة الخصم',
      taxRate: 'ضريبة القيمة المضافة',
      priceAfterDiscount: 'السعر بعد الخصم',
      total: 'الإجمالي',
      pay: 'الدفع',
      pay2: 'الدفع الإلكتروني',
      invoicePayment: 'فاتورة غير مسددة',
      thanks: 'شكرًا لك',
      boughtpackageSuccess: 'تم شراء الباقة بنجاح نتمني لك الاستمتاع بالخصائص الخاصة بها ',
      MaxNumerCountOfPackage:
        'لا يمكن شراء الباقة وذلك لان يوجد عدد قضايا او عقود او وكالات اكبر من الحد الاقصي للاضافة في هذه الباقة',
      branchEmployee:
        'لقد تجاوزت الحد الأقصى لعدد المستخدمين و الفروع .الرجاء حذف البعض لتتمكن من الاشتراك في الباقة الجديدة',
      paymentSend:
        'تم إرسال الإيصال إلى مدير النظام وجاري مراجعته لتفعيل الباقة',
      payMessage:
        'هذه الباقة تحتوي على فاتورة سابقة ، لا يمكنك إضافة فاتورة أخرى للباقة، الرجاء مراجعة الفواتير',
      downloadLink: 'قم بتحميل المرفق من هذا الرابط',
      uploadFileFirst: 'من فضلك قم بإرفاق الايصال',
      Unlimited: "عدد مستخدمين غير محدود ",
      CustomerManagement: "إدارة العملاء ",
      ContractManagement: "إدارة العقود ",
      ProjectManagement: 'إدارة المشاريع',
      accountingSummary: 'الملخص المحاسبي'
    },
    Dashboard: {
      hello: 'أهلاً بك ,',
      lawsuits: 'القضايا',
      totalLawsuit: ' إجمالي القضايا',
      lawsuitCompleted: 'قضية منجزة',
      contract: 'العقود',
      totalContract: 'إجمالي العقود',
      contractCompleted: 'عقد منجز',
      sittings: 'الجلسات',
      totalSittings: ' إجمالي الجلسات',
      sittingCompleted: 'جلسة منجزة',
      lawsuit: 'قضية',
      branch: 'فرع',
      client: 'عميل',
      dashboardSubTitle:
        'تتيح لوحة البيانات عرض أحدث التحديثات المتعلقة بالأعمال القانونية المتاحة لديك.',
      duty: 'المهام',
      totalTasks: 'إجمالي المهام ',
      tasksCompleted: 'مهمة منجزة',
      startDate: 'من التاريخ',
      endDate: 'إلى التاريخ',
      agency: 'الوكالات',
      employee: 'الموظفين',
      details: 'تفاصيل',
      totalBranches: 'إجمالي الفروع',
      totalAgency: 'إجمالي الوكالات',
      totalEmployee: 'إجمالي الموظفين',
      branches: 'الفروع',
      oneAgency: 'وكالة',
      oneEmployee: 'موظف',
      clients: 'العملاء',
      totalclient: 'إجمالي العملاء ',
      NoData: 'لا يوجد بيانات',
      Lawsuit: 'قضية',
    },
    shared: {
      Greg: 'ميلادي',
      Hijri: 'هجري',
      place: 'يوم/شهر/سنة',
      noOfEmployee: 'عدد الموظفين المتبقيين:',
      noOfBranches: 'عدد الفروع المتبقية:',
    },
    landingPage: {
      q1: '1. ما هو نظام "قيد"؟',
      answer1:
        'قيد" هو نظام متكامل لإدارة القضايا والأعمال القانونية، يهدف إلى مساعدة المحامين والمستشارين القانونيين في تنظيم وإدارة القضايا والمستندات" بشكل فعّال وآمن.',
      q2: '2. كيف يمكنني التسجيل في نظام "قيد"؟',
      answer2:
        'للتسجيل في نظام "قيد"، يمكنك زيارة موقعنا الإلكتروني واتباع خطوات التسجيل المبينة هناك. ستحتاج إلى تقديم بعض المعلومات الأساسية لإنشاء حسابك.',
      q3: '3. ما هي الميزات الرئيسية لنظام "قيد"؟',
      answer3: 'تشمل الميزات الرئيسية لنظام "قيد":',
      answer31: 'إدارة القضايا والمستندات',
      answer32: 'تتبع المواعيد والمهام',
      answer33: 'التواصل والتعاون بين أعضاء الفريق',
      answer34: 'إعداد التقارير والتحليل',
      answer35: 'حماية البيانات وأمان المعلومات',
      answer36: 'التكامل مع الأنظمة الأخرى',
      q4: '4. كيف يتم حفظ وتأمين بياناتي في نظام "قيد"؟',
      answer4:
        'نحن نستخدم تقنيات التشفير المتقدمة لحماية بياناتك، ونوفر نسخ احتياطي دوري لضمان أمان المعلومات. فقط الموظفين المخولين يمكنهم الوصول.إلى البيانات، مما يضمن سرية وأمان معلوماتك',
      q5: '5. هل يمكنني الوصول إلى نظام "قيد" من أي مكان؟',
      answer5:
        'نعم، نظام "قيد" هو نظام قائم على السحابة، مما يتيح لك الوصول إلى بياناتك وإدارة قضاياك من أي مكان وفي أي وقت عبر الإنترنت.',
      q6: '6.كيف يمكنني استرجاع كلمة المرور الخاصة بي؟',
      answer6:
        'إذا نسيت كلمة المرور الخاصة بك، يمكنك النقر على رابط "نسيت كلمة المرور" في صفحة تسجيل الدخول واتباع التعليمات لاسترجاع كلمة المرور عبر بريدك الإلكتروني المسجل.',
      q7: '7.هل يمكنني تجربة النظام قبل الاشتراك؟',
      answer7:
        'نعم، نقدم نسخة تجريبية مجانية لنظام "قيد". يمكنك التسجيل للحصول على النسخة التجريبية واستكشاف ميزات النظام قبل اتخاذ قرار الاشتراك.',
      q8: '8.ماذا يحدث لبياناتي إذا قررت إلغاء الاشتراك؟',
      answer8:
        '.في حالة إلغاء الاشتراك، يمكنك طلب نسخة من جميع بياناتك المحفوظة في النظام. نضمن توفير البيانات لك بشكل آمن وسهل الاسترجاع',
      q9: '9.كيف يمكنني التواصل مع فريق الدعم الفني؟ ',
      answer9:
        'يمكنك التواصل مع فريق الدعم الفني عبر البريد الإلكتروني (support@) أو عبر الهاتف . نحن متواجدون لمساعدتك والإجابة على جميع استفساراتك',
      q10: '10.هل يمكن تكامل نظام "قيد" مع أنظمة أخرى؟',
      answer10:
        'نعم، نظام "قيد" يدعم التكامل مع العديد من الأنظمة المالية والإدارية الأخرى، مما يسهل تزامن البيانات وتوحيد العمليات المختلفة في مكتبك.',
      des: 'إذا كان لديك المزيد من الأسئلة أو تحتاج إلى مساعدة إضافية، لا تتردد في التواصل معنا. نحن هنا لخدمتك وضمان تجربة سلسة وممتعة مع نظام "قيد"',
    },
    services: {
      mainTitle: 'عزز أعمالك بخدمات قيد',
      service1Title: "إدارة المهام",
      service1Text: "أدر مهام موظفيك عبر المنصة، ستُرسل لهم تلقائياً إشعارات تذكيرية بالمهام والمواعيد المستقبلية، لتتابع تقدّم العمل وإنجاز المهام من لوحة تحكمك. ",

      service2Title: "إدارة الموظفين",
      service2Text: "سجّل موظفيك حسب عدد المستخدمين لديك، وتابع تكليفهم بالأعمال القانونية من خلال المنصة بإجراءات تنظيمية تساعدهم على الإنجاز. ",

      service3Title: "إدارة المكتب الرئيسي",
      service3Text: "مكتبك الرقمي معك في كل مكان، بإمكانك إدارة قضاياك ومواعيدك و مستنداتك كلها من لوحة تحكم واحدة في أي وقت. ",

      service4Title: "إدارة الفروع",
      service4Text: "لزيادة التنظيم الإداري لمكتبك ذي الفروع المتعددة، بإمكانك تخصيص لوحة تحكم خاصة بكل فرع لإدارته بشكل مستقل عن غيره من الفروع",

      service5Title: "إدارة العملاء",
      service5Text: "نحفظ بيانات عملائك بتسجيلها في قائمة منظّمة لسهولة إدارتها والوصول إليها، بما يضمن فاعلية في التواصل وتقديم الخدمات. ",

      service6Title: "إدارة الجلسات",
      service6Text: "ننظّم مواعيد الجلسات القضائية بجدولتها،  وتذكير الموظفين المُكّلفين بتفاصيلها ومتطلباتها المرتبطة بالقضايا للسعي على مصالح العملاء. ",

      service7Title: "إدارة العقود ",
      service7Text: "بملفات رقمية منظّمة، أدر عقود موظفيك وعقود عملائك القائمين وشركاء مكتبك في مكان واحد، بأدوات تخزينية متقدّمة وآمنة.",

      service8Title: "إدارة الوكالات",
      service8Text: "ننظم الوكالات التي تم تفويضها لك من العملاء، حسب نوعها العام، أو الخاص بقضية معينة، مع تاريخ البداية والنهاية بسهولة في البحث والوصول. ",

      service9Title: "إدارة الوثائق",
      service9Text: "نوفر لك مساحة كافية وآمنة لوثائق ومستندات عملائك في خوادم تخزين خاصة داخل السعودية، مع إمكانية تصنيفها عبر أدوات تقنية متقدمة.",

      service10Title: "إدارة القضايا",
      service10Text: " نجمع قضاياك في لوحة تحكم واحدة ونقيّد كل قضية بكل تفاصيلها، مع إمكانية تحديثها بسهولة، لتركّز على متابعة تقدّمك. ",

      btn: 'اشترك الآن',
    },
    about: {
      princeHead: "ما يمكننا فعله هو تشجيع قوة القانون",
      crownPrince: "ولي العهد",
      princeName: "صاحب السمو الملكي الأمير محمد بن سلمان آل سعود​",
      learnMore: "تعرف علينا أكثر",
      qydPlatform: "منصة قيد",

      platformPoints: {
        point1: 'إيماناً منّا بأن المجتمع يزدهر بإحقاق الحق والسعي إلى العدالة، أطلقنا "قيد" لدعم العاملين في المجال القانوني بحلول رقمية ذكية، تنظّم الأعمال وتنمّيها، بنموذج عمل مبتكر لتسهيل العمليات ورفع كفاءة الخدمات المقدّمة.​',
        point2: ' "قيد" تقيّد جميع أعمالك وتديرها من مكان واحد في أي وقت، عبر تنظيم العقود وإدارة القضايا والجلسات والمهام وتخزين الوثائق، مع بوابة خاصة لإدارة علاقات العملاء وتنمية الموارد المالية.​',
        point3: 'صممنا المنصة لتكون قادرة على حفظ المستندات بمساحات كبيرة وخصوصية عالية في خوادم داخل السعودية، تختصر الوقت الطويل، وتقلل التكاليف، وترفع كفاءة الأعمال مما يسرّع من نموها، ويزيد من ثقة العملاء.​',

      },
      message: "الرسالة",
      messageText: "إدارة الأعمال القانونية بحلول رقمية ذكية من مكان واحد. ​",
      vision: "الرؤية",
      visionText: "التحوّل الرقمي الشامل في إدارة المنظومة القانونية للقطاع الخاص بالمملكة.​",
      qydGoals: "أهداف قيد",
      goalsHead: "نبتكر الحلول ونعزز كفاءة الأداء لتحقيق مصالح المجتمع القانوني ",
      goalsPoints: {
        point1Title: "تعزيز التواصل",
        point1Text: "تعزيز التواصل بين أطراف المجتمع القانوني لتحقيق الأهداف والمصالح. ​",

        point2Title: "الخصوصية والسرية",
        point2Text: "الحفاظ على الخصوصية والسرية في تخزين البيانات و الوثائق.",

        point3Title: "الحلول الرقمية",
        point3Text: "تقديم حلول رقمية متجددة لمعالجة تحديات تنمية العمل القانوني.",

        point4Title: "أداء الأعمال",
        point4Text: "تسهيل أداء الأعمال ورفع كفاءتها وجودة مخرجاتها بإثراء المحتوى القانوني.",
      },


    },
    projectManagement: {
      title: "إدارة المشاريع",
      search: "البحث",
      projectType: "نوع المشروع",
      clientName: "اسم العميل",
      projectStatus: "حالة المشروع",
      selectPlaceholder: "اختر من هنا...",
      searchButton: "بحث",
      projectList: "قائمة المشاريع",
      totalProjects: "إجمالي المشاريع:",
      addProject: "إضافة مشروع",
      dataNotFound: "لا توجد مشاريع",
      columns: {
        projectName: "اسم المشروع",
        projectType: "نوع المشروع",
        units: "عدد الوحدات",
        unitPrice: "سعر الوحدة",
        actions: "إجراءات",
        associatedWithCase: 'مرتبط بقضية',
        associatedWithContract: 'مرتبط بعقد',
      },
      startDate: "تاريخ البداية",
      endDate: "تاريخ النهاية",
      addNewProject: 'إضافة مشروع جديد',
      mainPage: 'الصفحة الرئيسية',
      projectManagement: 'إدارة المشاريع',
      addProjectButton: 'إضافة مشروع',
      projectDetails: 'تفاصيل المشروع',
      underProgress: 'قيد التقدم',
      assignProjectManager: 'تعيين مسؤول على المشروع',
      selectEmployee: 'اختر من الموظفين',
      projectName: 'اسم المشروع',
      service: 'خدمة',
      consultation: 'استشارات',
      associatedWithBranch: 'هل المشروع مرتبط بقضية',
      yes: 'نعم',
      no: 'لا',
      contractManaged: 'هل المشروع مرتبط عن بعقد؟',
      unitPrice: 'سعر الوحدة',
      unitCount: 'عدد الوحدات',
      saudiRiyal: 'ريال سعودي',
      delete: 'حذف',
      reviewCase: 'مراجعة على قضية',
      next: 'التالي',
      saveLater: 'استكمال لاحقًا',
      back: 'رجوع',
      convertToOpportunity: 'التحويل إلى فرصة',
      reviewDetails: 'مراجعة التفاصيل',
      priceOffer: 'عرض السعر',
      consultationType: 'نوع الاستشارة',
      consultationName: 'اسم الاستشارة',
      serviceDescription: 'وصف المشروع',
      writeServiceDescription: 'اكتب هنا وصف الخدمة ...',
      searchByCaseName: 'ابحث بإسم القضية',
      chooseCaseAssociatedWithProject: 'اختر قضية مرتبطة بالمشروع',
      unregisteredCase: 'قضية غير مسجلة ؟',
      addNewCase: 'اضافة قضية جديدة',
      LaborConsultation: 'استشارة عمالية',
      PersonalStatusConsultation: 'استشارة أحوال شخصية',
      RealEstateConsultation: 'استشارة عقارية',
      BusinessConsulting: 'استشارة تجارية',
      GeneralConsultation: 'استشارة عامة',
      Other: 'آخرى',
      ExecutionRequests: "طلبات التنفيذ",
      PerformanceOrders: "أوامر الأداء",
      AmicableSettlement: "التسوية الودية",
      Reports: "التقارير",
      Arbitration: "التحكيم",
      Collection: "التحصيل",
      CreateContract: "إنشاء عقد",
      DraftingContract: "صياغة عقد",
      OtherProject: "اسم المشروع",
      selectAssociatedContract: 'اختر العقد المرتبط بالمشروع',
      searchContractByName: 'ابحث باسم العقد',
      addNewContract: 'عقد غير مسجل ؟   ',
      addNewContract2: 'إضافة عقد جديد',
      selectClient: 'اختر العميل',
      searchByName: 'ابحث باسم العميل',
      addNewClient: 'عميل غير مسجل ؟   ',
      addNewClient2: '    إضافة عميل ',
      addTasksToProject: 'إضافة مهام إلى المشروع',
      addTask: 'إضافة مهمة',
      taskName: 'اسم المهمة',
      potentialClient: 'عميل محتمل',
      responsible: 'المكلف',
      taskStartDate: 'تاريخ بدء المهمة',
      taskEndDate: 'تاريخ نهاية المهمة',
      taskStatus: 'حالة المهمة',
      attachments: 'التصنيفات والمرفقات',
      selectCategory: 'اختر التصنيف',
      category1: 'تصنيف 1',
      category2: 'تصنيف 2',
      attachmentName: 'اسم المرفق',
      file: 'الملف',
      taskList: 'قائمة المهام',
      categories: 'التصنيفات',
      phoneNumber: 'رقم الهاتف (إن وجد)',
      enterPhoneNumber: 'أدخل رقم الهاتف',
      offerStartDate: 'تاريخ بداية العرض',
      offerEndDate: 'تاريخ نهاية العرض',
      customerName: 'اسم العميل',
      enterCustomerName: 'أدخل اسم العميل',
      customerTaxNumber: 'الرقم الضريبي للعميل',
      enterCustomerTaxNumber: 'أدخل الرقم الضريبي للعميل',
      nationalAddress: 'العنوان الوطني',
      city: 'المدينة',
      enterCity: 'أدخل المدينة',
      neighborhood: 'الحي',
      enterNeighborhood: 'أدخل الحي',
      postalCode: 'الرمز البريدي',
      enterPostalCode: 'أدخل الرمز البريدي',
      financialDetails: 'التفاصيل المالية',
      enterUnitPrice: 'أدخل سعر الوحدة',
      taxRate: 'معدل الضريبة',
      enterTaxRate: 'أدخل معدل الضريبة',
      totalWithTax: 'الإجمالي مع الضريبة',
      enterTotalWithTax: 'أدخل الإجمالي مع الضريبة',
      uploadLogo: 'ارفاق شعار المنشأة',
      uploadLogoBtn: 'رفع الشعار',
      projectSerialNumber: 'الرقم التسلسلي للمشروع',

      projectManager: 'مسؤول المشروع',
      tasksCount: 'عدد المهام',
      actions: "إجراءات",
      tasks: 'المهام',
      financialDepartment: 'قسم المالية',
      legalDepartment: 'قسم القضايا',
      contractsDepartment: 'قسم العقود',
      New: 'جديد',
      InProgress: 'قيد التقدم',
      AcceptOffer: 'موافقة على العرض',
      RemainingPayments: 'دفعات متبقية',
      CompletedPayments: 'دفعات مكتملة',
      backToProjectDetails: 'الرجوع إلى تفاصيل المشروع',
      selectTask: "أختر مهمة",
      select: "أختر...",
      taskNotRegsiter: "مهمة غير مسجلة؟",
      ShouldInsertOneEmployeeAtLeastAndChangeToChance: 'يجب علي الاقل اختيار عميل محتمل واحد وتحويل الي فرصة',
      converted: "تم التحويل الى فرصة",
      header: "عرض السعر",
      taxNumber: "الرقم الضريبي لمنشأتك (إن وجد)",
      taxNumberPlaceholder: "أدخل الرقم الضريبي الخاص بك",
      gregorian: "ميلادي",
      hijri: "هجري",
      clientTaxNumber: "الرقم الضريبي للعميل",
      clientTaxNumberPlaceholder: "أدخل الرقم الضريبي للعميل",
      clientNationalAddress: "العنوان الوطني للعميل",
      district: "الحي",
      street: "الشارع",
      buildingNumber: "رقم المبنى",
      subNumber: "الرقم الفرعي",

      FileRequired: 'هذا الحقل اجباري',
      financialHeader: "التفاصيل المالية",
      priceUnit: "وحدة قياس السعر",
      units: "وحدات",
      taxAmount: "المبلغ الخاضع للضريبة",
      totalAmountWithTax: "المبلغ الإجمالي مع الضريبة",
      vatAmount: "مبلغ الضريبة - VAT",
      addressHeader: "العنوان الوطني للمنشأة",
      logoUpload: "إرفاق شعار وختم المنشأة",
      changeLogo: "تغيير الشعار",
      uploadStamp: "رفع صورة ختم المنشأة",
      entityStamp: "ختم المنشأة",
      note: "ملاحظة: يرجى إرفاق الشعار والتوقيع بخلفية بيضاء وصيغة PNG.",
      Units: "وحدات",
      Lawsuits: "جلسات",
      Visits: "زيارات",
      phoneNumber2: 'رقم الهاتف',
      add_invoice: "إضافة فاتورة",
      view_details: "استعراض التفاصيل",
      edit_data: "تعديل البيانات",
      download_quote: "تحميل عرض السعر",
      financial_section: "قسم المالية",
      main_page: "الصفحة الرئيسية",
      projects_management: "إدارة المشاريع",
      price_quote: "عرض السعر",
      payment_notice: "إشعار سداد",
      price_quote_data: "بيانات عرض السعر",
      offer_end_date: "تاريخ انتهاء العرض",
      offer_date: "تاريخ العرض",
      project_serial_number: "الرقم التسلسلي للمشروع",
      client_name: "اسم العميل",
      total_with_tax: "الإجمالي شامل الضريبة",
      is_offer_approved: "هل تمت الموافقة على العرض",
      bills: "فواتير",
      includes_invoice: 'يتضمن فاتورة؟',
      is_paid: 'هل توجد دفعات متبقية؟',
      issue_date: 'تاريخ إصدار الإشعار',
      serial_number: 'الرقم التسلسلي',
      view_notice: 'استعراض الإشعار',
      add_payment_invoice: 'إضافة فاتورة سداد',
      payment_number: "رقم الدفع",
      invoice_number: "رقم الفاتورة",
      invoice_issue_date: "تاريخ إصدار الفاتورة",
      view_invoice: "استعراض الفاتورة",
      download_invoice: "تحميل الفاتورة",
      add_payment_notice: "إضافة إشعار سداد",
      create_payment_notice: "إنشاء إشعار سداد",
      UpdatedSuccessFully: "تم التعديل بنجاح",
      AddedSdadaSuccessFully: "تم اضافة فاتورة اشعار سداد بنجاح",

      project_name: "اسم المشروع",
      tax_number: "الرقم الضريبي للمنشأة",
      offer_serial: "الرقم التسلسلي للعرض",
      establishment_banking_info: "المعلومات البنكية للمنشأة",
      date_picker: "حدد تاريخ البداية",
      amount: "المبلغ",
      description: "الوصف",
      bank_name: "اسم البنك",
      iban_number: "رقم الآيبان",
      account_holder_name: "اسم صاحب الحساب",
      select_bank_name: "اسم البنك",
      example_description: "مثال: مقابل الدفعة الثانية بناءً على عقد الخدمات القانونية شامل ضريبة القيمة المضافة",
      submit: "حفظ",
      cancel: "إلغاء",
      task_list: "قائمة المهام",
      task_name: "اسم المهمة",
      assigned_to: "المكلف",
      task_status: "حالة المهمة",
      start_date: "تاريخ بدء المهمة",
      end_date: "تاريخ نهاية المهمة",
      activation_status: "حالة التفعيل",
      details: "التفاصيل",
      edit_task: "تعديل المهمة",
      task_details: "تفاصيل المهمة",
      ongoing: "جارية الآن",
      completed: "مكتملة",
      contract_list: "قائمة العقود",
      total_contracts: "إجمالي العقود",
      contract_title: "عنوان العقد",
      contract_status: "حالة العقد",
      branch_name: "اسم الفرع",
      creation_date: "تاريخ إنشاء العقد",
      project_associated: "عقد مرتبط بمشروع",
      view: "استعراض",
      assigned_members: "المكلفين",
      active: "نشط",
      inactive: "غير نشط",
      enabled: "مفعل",
      disabled: "غير مفعل",
      case_management: "إدارة القضايا",
      total_cases: "إجمالي القضايا",
      case_number: "رقم القضية",
      case_number_in_court: "رقم القضية في المحكمة",
      case_type: "نوع القضية",
      case_status: "حالة القضية",
      court: "الجهة",
      accounting_summary: "الملخص المحاسبي",
      export_data: "تصدير البيانات",
      pdf: "PDF",
      excel: "Excel",
      account_statement: "كشف الحساب",
      reference_number: "الرقم المرجعي",
      transaction_date: "تاريخ العملية",
      transaction_type: "نوع العملية",
      project: "اسم المشروع",
      debit: "مدين",
      credit: "دائن",
      trial_balance: "ميزان المراجعة",
      payments_table: "جدول السدادات",
      total_amount: "الإجمالي",
      start_date2: "اختر تاريخ البداية",
      end_date2: "اختر تاريخ النهاية",
      payment_table: "جدول السدادات",
      payment_date: "تاريخ السداد",
      paid_invoice: "الفاتورة المسددة",

      opening_balance: "اول المدة",
      movement: "الحركة",
      closing_balance: "اخر المدة",
      balance: "الرصيد",

      project_added_successfully: "تم إضافة مشروعك بنجاح",
      review_and_download: "يمكنك مراجعة عرض السعر و تحميله من هنا او الانتقال الى صفحة المشاريع",
      quote_preview: "عرض سعر",
      navigate_to_projects: "الانتقال الى صفحة المشاريع",
      vat_number: "الرقم الضريبي",
      issue_date2: "تاريخ الإصدار",
      expiry_date: "تاريخ الانتهاء",
      client_address: "عنوان العميل",
      item_number: "الرقم",
      service2: "الخدمة",
      unit_price: "سعر الوحدة",
      taxable_amount: "المبلغ الخاضع للضريبة",
      tax_rate: "نسبة الضريبة",
      tax_amount: "مبلغ الضريبة",
      subtotal: "إجمالي المبلغ بدون الضريبة",
      tax_total: "إجمالي الضريبة",
      total_amount2: "الإجمالي مع الضريبة",
      total_with_tax2: "الإجمالي مع الضريبة",
      client_vat: "الرقم الضريبي للعميل",
      view_payment_notice: "عرض إشعار السداد",


      to: "إلى",
      service_name: "اسم الخدمة",
      account_name: "اسم الحساب",
      bank_transfer: "حوالة بنكية",
      iban: "رقم الآيبان",
      note_text:
        "نود التنبيه على ضرورة مطابقة اسم المستفيد دون اختصار تفادياً لعدم وصول الحوالة وذلك بناء على توجيهات البنك المركزي السعودي. كما هو موضح في إشعار السداد أعلاه.",
      download: "تحميل",

      simplified_tax_invoice: "فاتورة ضريبية مبسطة",
      TaxInvoice: "فاتورة ضريبية ",

      invoice_due_date: "تاريخ الانتهاء",
      payment_reference: "مرجع السداد",
      product_name: "اسم المنتج",
      unified_id: "الهوية الموحدة للشركة غير الحكومية",
      client_tax_number: "الرقم الضريبي للعميل",
      // taxable_amount: "المبلغ الخاضع للضريبة",
      // tax_rate: "نسبة الضريبة",
      // tax_amount: "مبلغ الضريبة",
      // total_with_tax: "الإجمالي مع الضريبة",
      // subtotal: "إجمالي المبلغ بدون الضريبة",
      // tax_total: "إجمالي المبلغ الضريبي",
      // total_amount: "إجمالي المبلغ مع الضريبة",
      download_invoice2: "تحميل الفاتورة",
      Invoice: "فاتورة",
      PaymentNotice: "اشعار سداد",
      invoice_number2: "رقم اشعار السداد",
    },
    auth: {
      newAccount: "حساب جديد",
      phone: "رقم الهاتف",
      email: "البريد الإلكتروني",
      addAccount: "تسجيل حساب",
      otp: {
        enterCode: 'أدخل رقم التأكيد',
        sentMessage: 'تم ارسال رقم التأكيد الى رقم هاتفك',
        sentMessage2: 'تم ارسال رقم التأكيد الى البريد الالكروني',
        resendMessage: 'إعادة ارسال الرمز بعد',
        resendCode: 'إعادة الارسال',
        confirmAccount: 'تأكيد الحساب',
        editPhoneNumber: 'تعديل رقم الجوال',
        editEmail: "تعديل البريد الالكتروني"
      },
      selectPackage: "اختر الباقة المناسبة لك",
      packagePrice: "سعر الباقة يتغير حسب عدد المستخدمين",
      userCount: "عدد المستخدمين",
      startTrial: 'بدء الفترة التجريبية',

    },

    customerManagement: {
      EmailOrPhoneIsExists: 'البريد الالكتروني او  رقم الجوال مسجل من قبل ',
      userManagement: "إدارة المستخدمين",
      employeeList: 'قائمة المستخدمين',
      management: 'إدارة',
      actions: "الإجراءات",
      employee: 'المستخدمين',
      employee1: "مستخدم",
      search: 'البحث بالاسم أو البريد الإلكتروني',
      phonesearch: 'البحث برقم الجوال',
      completed: 'مكتمل',
      inCompleted: 'غير مكتمل',
      active: 'مفعل',
      inActive: 'غير مفعل',
      All: 'الكل',
      allCustomers: 'إدارة المشتركين',
      activeMembers: 'الأعضاء النشيطين',
      playerName: 'اسم المستخدم',
      playerStatus: ' الحالة  ',
      mobileNumber: 'رقم الجوال',
      Email: 'البريد الإلكتروني',
      country: 'الدولة',
      accountStatus: 'حالة الحساب',
      emailStatus: 'حالة  تفعيل البريد ',
      Block: 'حظر',
      blockStatus: 'حالة الحظر',
      total: 'المجموع',
      Blocked: 'محظور',
      notBlocked: 'غير محظور',
      NotFound: 'لا توجد بيانات متاحة',
      addNew: 'مستخدم جديد',
      Details: 'تفاصيل الحساب',
      edit: 'تعديل البيانات',
      delete: 'حذف',
      branch: 'الفرع',
      Confirmed: 'مفعل',
      NotConfirmed: 'غير مفعل',
      ok: 'التأكيد',
      cancel: 'إلغاء',
      confirmModalTitle: 'تأكيد',
      activeMessage: 'هل تريد تغيير حالة الحساب؟',
      changeAccountStatus: 'تم تغيير حالة الحساب بنجاح',
      branchName: 'اسم الفرع',
      more: 'عرض المزيد',
      allbranch: 'جميع الفروع',
      editEmployee: 'تعديل بيانات مستخدم',
      addEmployee: 'إضافة مستخدم جديد',
      name: ' اسم المستخدم',
      writeName: ' اكتب اسم المستخدم',
      SelectOption: 'اختر',
      email: ' ادخل البريد الإلكتروني للمستخدم',
      phoneNumber: 'رقم الجوال',
      save: 'اضافة مستخدم',
      confirmDel: 'أنت على وشك حذف المستخدم . هل أنت متأكد؟',
      addSuccess: 'تم إضافة المستخدم بنجاح',

      editSuccess: 'تم تعديل بيانات المستخدم بنجاح',
      licenceNo: ' ادخل رقم الترخيص',
      licenceDate: 'تاريخ اصدار الترخيص',
      licenceEndDate: 'تاريخ انتهاء الترخيص',
      confirmDelte: 'عند تغيير الفرع سيتم حذف جميع المهام الموكلة لهذا المستخدم',
      deleteuccess: ' تم حذف الفرع بنجاح',
      confirmDelteBranch: 'تأكيد حذف موظف من الفرع',
      employeeType: "نوع المستخدم",
      Individual: "فرد",
      teamName: "اسم الفريق",
      details: 'بيانات المستخدم ',
    },
    deactivate: {
      CONFIRM_DEACTIVATION: 'تأكيد تعطيل حسابك',
      DEACTIVATION_WARNING: 'سيتم الآن تعطيل حسابك والخروج من المنصة.',
      DEACTIVATE_BUTTON: 'تعطيل الحساب',
      CANCEL_BUTTON: 'إلغاء',
      DEACTIVATE_ACCOUNT: 'تعطيل الحساب',
    },
    errorPage: {
      ERROR_TITLE: 'حدث خطأ ما!!',
      ERROR_DESCRIPTION: 'يبدو أنك اتبعت رابطًا لا يعمل أو أن الصفحة بها عطل تقني.',
      ERROR_BUTTON: 'العودة للصفحة الرئيسية',
      SERVER_ERROR_TITLE: '500 خطأ في الخادم الداخلي',
      SERVER_ERROR_MESSAGE: 'حدث خطأ بالخادم الداخلي. يرجى المحاولة لاحقًا.',
      SERVER_ERROR_BUTTON: 'العودة للصفحة الرئيسية'
    }
  },

};
